import React, { useState } from 'react';
import '../Styles/Competitions.css';
import '../Styles/Contact.css';
import axios from 'axios';
import { Alert, AlertTitle } from '@mui/material/';
import { faL } from '@fortawesome/free-solid-svg-icons';

function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://sorare-album.com/public/contact-mail', {
      arg_name: name,
      arg_email: email, 
      arg_message: message, 
    }).then((response) => {
        setSuccess(true)
      })
      .catch((error) => {
      });

    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className='mainbody'>
      <p className='headline_comp'>Contact us</p>

      <div className='contact_form'>
        <p className='contact_text'>Dear Sorare Manager,<br></br>
        <br></br>
        we will gladly take care of your request. No matter if: 
        <br></br><br></br> 
        <ul>
          <li>you have questions</li> 
          <li>need help with a problem</li>
          <li>have suggestions for improvement</li>
          <li>you have praise you want to express</li>  
        </ul>
        <br></br><br></br>
        We are looking forward to your message.
        </p>
      </div>

      <form className='contact_form' onSubmit={handleSubmit}>
        <label className='contact_label'>Name:</label>
        <input className='contact_input' type="text" value={name} onChange={(e) => setName(e.target.value)} />
        
        <label className='contact_label'>Email:</label>
        <input className='contact_input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>

        <label className='contact_label'>Message:</label>
        <textarea className='contact_textarea' value={message} onChange={(e) => setMessage(e.target.value)} required/>
        
        {success ? 
        <><Alert onClose={() => setSuccess(false)} severity="success">
        <AlertTitle>Success</AlertTitle>
        Your e-mail has been sent — <strong>We'll do our best to answer your message promptly.</strong>
        </Alert><br></br></> 
        : <></>}
       
        {error ? 
        <><Alert onClose={() => setError(false)} severity="error">
        <AlertTitle>Error</AlertTitle>
        Your e-mail couldn't be send <br></br> <strong>We're sorry. You could send a mail manually to info@sorare-album.com</strong>
        </Alert><br></br></> 
        : <></>}

        <button className='contact_button' type="submit">Submit</button>
      </form>
    </div>
  );
};  

export default Contact;
