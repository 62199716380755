import React, { useRef, useCallback, useContext} from 'react';
import "../Styles/Album.css";
import {useState, useEffect} from 'react'
import { useParams, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { ColorRing } from 'react-loader-spinner';
import { Alert, AlertTitle } from '@mui/material/';
import { toPng } from 'html-to-image';

const AlbumComp = () => {

    const auth = useContext(AuthContext);  

    const rarity = auth.auth.rarity
    const userID = auth.auth.sorareID 
    const common = String(auth.auth.common); 

    //const userID = "54a5b139-2ce7-40ba-aca9-94e91fc30c5d";
    /* const userID = undefined;
    const rarity = undefined;
    const common = "true"; */
  
    var params = useParams();
    const season = params["season"]
    const slug = params["compslug"]

    var [teams, setTeams] = useState([])
    var [competition, setCompName] = useState("")
    var [compUrl, setCompUrl] = useState("")
    var [user, setUser] = useState()
    var [username, setUsername] = useState("")
    var [userpicture, setUserPicture] = useState("")
    var [userbanner, setUserBanner] = useState("")
    var [usershield, setUserShield] = useState("")
    var [data, setData] = useState([])
    var [datats, setDatats] = useState([])
    var [wallet, setWallet] = useState([])
    var [currentPage, setCurrentPage] = useState(0);
    var [activePage, setActivePage] = useState(1)
    var loading = true;
    var [loadingWallet, setLoadingWallet] = useState(true)    
    let currentteam = []    
    var players = []  
    var team = []  
    var slugwallet = []    

    useEffect(() => {
        async function fetchTeams() {  
            await fetch(`https://sorare-album.com/public/get/clubs/${slug}/${season}`).then(
            res => res.json()).then(teams =>{
                setTeams(teams)
                setCompName(teams[0].compname)
                setCompUrl(teams[0].compUrl)            
            })
        }
        fetchTeams();
    }, []);

    teams.forEach(t => {
        if(t.cardsprinted == "True"){
            currentteam.push(t.clubslug)
        }
    });    

    useEffect(() => {
        async function fetchUser() {  
            if(userID != undefined){
                await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/user/${userID}`).then(
                    res => res.json()).then(user =>{
                        setUser(user)  
                        setUsername(user.nickname)                 
                        setUserPicture(user.pictureUrl)    
                        setUserBanner(user.profile.clubBanner.pictureUrl)             
                        setUserShield(user.profile.clubShield.pictureUrl)             
                    })
            }
        }
        fetchUser();
    }, [userID]);

    async function fetchData(te) {    
        //https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${te}
        //https://fastapi-1-v3385005.deta.app/cards/?season=${season}&clubname=${te}
        //https://nowag.pythonanywhere.com/ 
        //https://flask-production-a187.up.railway.app/       
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${te}`).then(                
            function(response) {
                response.json().then(function(data){
                    setData(data)    
                    players.push(data) 
                })              
            }
            ).then(data =>{
            /* setData(data)     
            if(data.result != "none"){
            players.push(data)} */
        }) 
        .catch(function(err) { console.log(err) })          
        setDatats(players)
    };

    async function fetchWallet(te) {      
        //PHP API: https://sorare-album.com/public/userclubcards/${te}/${rarity}/${userID}/${common}          
        //https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${te}&${rarity}&${userID}&${common}
        //https://fastapi-1-v3385005.deta.app/userclubcards/?club=${te}&rarity=${rarity}&userID=${userID}&common=${common}
        //https://flask-production-a187.up.railway.app/
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${te}&${rarity}&${userID}&${common}`).then(
            res => res.json()                
        ).then(wallet =>{
            wallet.forEach(w =>{
                if(w.node.season.startYear == season){
                    slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp, w.node.team.slug])
                }
            }
        )
        setWallet(slugwallet)
        })
        .catch(err => console.log(err))    
    };

    useEffect(() => {
        /* async function fetchData(te) {    
            await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${te}`).then(                
                res =>  res.json()
                ).then(data =>{
                setData(data)     
                if(data.result != "none"){
                players.push(data)}
            }) 
            .catch(err => console.log(err))          
            setDatats(players)
        };

        async function fetchWallet(te) {      
            //PHP API: https://sorare-album.com/public/userclubcards/${te}/${rarity}/${userID}/${common}          
            await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${te}&${rarity}&${userID}&${common}`).then(
                res => res.json()                
            ).then(wallet =>{
                wallet.forEach(w =>{
                    if(w.node.season.startYear == season){
                        slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp, w.node.team.slug])
                    }
                }
            )
            setWallet(slugwallet)
            })
            .catch(err => console.log(err))    
        }; */

        window.scrollTo(0, 0);
        currentteam.forEach((ct, idx) => {
            fetchData(ct)
            if(rarity != undefined && userID != undefined && common != undefined){
                fetchWallet(ct) 
                if(idx == currentteam.length-1){
                    setLoadingWallet(false)
                }
            } else if(idx == currentteam.length-1){
                setLoadingWallet(false)
            }
        })
    }, [JSON.stringify(currentteam), JSON.stringify(players), JSON.stringify(slugwallet)]);    
     
    var cardscounter = 0;

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
        return {
        ...obj,
        [item]: index,
        };
    }, {});

    const sortByRank = currentteam
    .reduce((obj, item, index) => {
        return {
        ...obj,
        [item]: index,
        };
    }, {});

    //Teams nach Tabelle sortieren
    datats = datats.sort(function(left, right){
        return sortByRank[left[0].team.slug] - sortByRank[right[0].team.slug];
    })     

    //Zusätzlich Karten nach Appearances sortieren
    datats.forEach(pl => {
        pl = pl.sort(function(left, right) {
        var position_order = sortByObject[left.player.positionTyped] - sortByObject[right.player.positionTyped];
        var app_order = parseInt(left.player.stats.appearances) - parseInt(right.player.stats.appearances);
        return position_order || -app_order;
    })});

    const renderTeams = () => {
        return teams.map(t => <><img className='coverClubs' src={t.pictureUrl}/>
        {/* <p className='featured_points'>Points: {t.points}</p> */}</>
        )
    }
        
    const pages = []    
    var pics = []  
    
    datats.forEach((pl, idx) => {
        //Clublogo anzeigen
        pics.push(<><div className='inner_card'>           
            <p className='nameheadline'>{pl[0].team.name}</p>
            <img className='imgfblogo' src={pl[0].team.pictureUrl} alt='clublogo'/>
            <p className='desc_club'>Founded: {pl[0].team.founded}</p>    
            
            <p className='desc_club'>Players: {pl.length}</p>
            </div></>)  

        pl.forEach(p => {
            players.push([p.player.displayName, p.team.slug])
            team.push(p.team.name)

            pics.push(<>                    
                <div className='inner_card'>
                    <p className='nameheadline'>{p.player.displayName}</p>
                    <img className='imgplayer' src={p.pictureUrl} alt='Spielerfoto' />
                    <p className='desc'>Appearances: {p.player.stats.appearances}</p>
                    <p className='desc'>Goals: {p.player.stats.goals} | Assists: {p.player.stats.assists}</p>
                    <p className='desc'>Position: {p.player.positionTyped}</p>
                    <p className='desc'>Age {p.age}</p>
                    <img className='imgflag' src={p.player.country.flagUrl} height="20px" alt='Spielerfoto' />
                </div>
                </>)
        })

        //index+1, da zu Beginn der pics noch das Logo eingefügt wurde. 
        players.forEach((p, index) =>{
            for (let i = 0; i < wallet.length; i++){
            if(p[0]==wallet[i][0] && p[1]==wallet[i][4]){
                cardscounter = cardscounter + 1;
                pics[index+1] = (<div className='inner_limited'>
                <img src={wallet[i][2]} className="imgcard" alt='Spielerfoto'/> 
                </div>)   
                break;
            }        
        }})

        const c = Math.ceil(pics.length/9)

        for (let index = 0; index < c; index++) {
            pages.push(pics.slice(9*index,(index+1)*9))             
        }      
        pics = []
        players = []
        if(idx == currentteam.length-1){
            loading = false;
        }
    }); 

    const renderFirstPage = () => {
        if(teams != []){
            return( 
                <div className='outer_cover'>
                    <p className='fontcover'>{competition}</p>
                    <p className='fontcover'>Season: {season}</p>
                    <img className='compLogo' src={compUrl}/>
                   
                    <div className='clubsBackground'>
                        {teams.length < 25 ? renderTeams() : <p></p>}
                    </div>

                    {user ? 
                    <div className='manager_data'>                    
                        <div className='featured_text'><p className='featured_text'>Manager: {username}</p></div>
                        <div className='featured_text'><p className='featured_text'>Cards: {cardscounter}</p></div>
                        {userpicture != "" && userpicture != undefined ? <img className='imgflag' src={userpicture} alt='Userpicture'/> : <p></p>}
                        {/* {userbanner!=undefined ? <img className='imgflag' src={userbanner} alt='Userpicture'/> : <p></p>}
                        {usershield!=undefined ? <img className='imgflag' src={usershield} alt='Userpicture'/> : <p></p>}*/}
                    </div> : <p></p> }
                </div>
            )
        }
    }

    pages.unshift(renderFirstPage())

    const ref = useRef(null)

    const onButtonClick = useCallback(() => {
    if (ref.current === null) {
        return
    }

    toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
        const link = document.createElement('a')   
        const docname = slug.toString() + "_" + season.toString()
        link.download = docname
        link.href = dataUrl
        link.click()
        })
        .catch((err) => {
        })
    }, [ref])

    const renderPages = (numb) => {
        if(numb != 0){
            return <div className='outer_box'>{pages[numb]}<p className='pagenumber'>{numb}</p> </div>
        }else{
            return <div>{pages[numb]}</div>
        }
    }
    
    const changePage = ({selected}) => {
        setCurrentPage(selected)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    }    

    return(  
        <div className='mainbody'>       
            <p className='headline_comp bold'>{competition} - {season}</p>
            <div>
            { //(userID != undefined && loading)  || (userID != undefined && loading) ?
            (loading || loadingWallet) ? 
            <div className='loading_spinner'>
                <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
                /></div> : 
                <>
                <Link to={`pdf`} state={{ data: datats, wallet: wallet }}><p className='headline'><button className='main_button'>Create PDF</button></p></Link>
                <p className='headline'><button className='main_button' text-align="center" justify-content="center" onClick={onButtonClick}>Download page as PNG</button></p>
                <div className='info_alert'>
                    <Alert variant="outlined" severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Currently we're experiencing problems with the PNG and PDF downloads on apple devices.
                    <br></br>
                    We're sorry for the inconvenience. For now you can download the pictures and PDF's on other devices correctly. We're working on a solution for the problem.
                    </Alert>
                </div>
                </>
            }
            <br></br>
            <div className='paginationDiv'>
            <Pagination
                currentPage={activePage}
                itemsPerPage={1}
                totalItems={pages.length}          
                onPageChange={handlePageChange.bind(this)}
                pageNeighbours={0}
                withGoToInput={true}
                startLabel={1}
                endLabel={pages.length}
                nextLabel={'Next'}
                prevLabel={'Prev'}
                customClassNames={{
                    rpbItemClassName:'custom-item',
                    rpbItemClassNameActive:'custom-item--active',
                    rpbGoItemClassName: 'custom-go-item',
                    rpbItemClassNameDisable: 'custom-item--disable', 
                    rpbProgressClassName: 'custom-progress-bar',
                    rpbRootClassName: 'custom-root',
                }}
                />
            </div>
            </div>
            <div ref={ref}>{renderPages(activePage-1)}</div>     
        </div>
    );
};

export default AlbumComp;