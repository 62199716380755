import React, {useState, useEffect} from 'react'
//CSS importieren

import {useParams, useLocation, Link} from 'react-router-dom';

const CompetitionDetails = (props) => {

    const location = useLocation();

    var params = useParams();
    params = params["compslug"]

    const [data, setData] = useState([])

    var [compname, setCompName] = useState("")
    var [complogo, setCompLogo] = useState("")
    var [compflag, setCompFlag] = useState("")

    useEffect(() => {
        fetch(`https://sorare-album.com/public/get/seasons/${params}`).then(
            res => res.json()
          ).then(data =>{
            setData(data)
          })

          if(location.state != undefined){
            setCompLogo(location.state.data[0])
            setCompName(location.state.data[1])
            setCompFlag(location.state.data[2])
          } else {
            fetch(`https://sorare-album.com/public/get/competitions/${params}`).then(
            res => res.json()
            ).then(team =>{
              setCompLogo(team[0].pictureUrl)
              setCompName(team[0].name)
              setCompFlag(team[0].flagUrl)
            })
          }
          window.scrollTo(0, 0);
    }, [])  
    
    const renderSeason = () => {
      return data.map(s =>
       /*  <div className='frame'>       
          <div className='frame_col'><p className='seasonheadline'>{s.season}</p></div>          
          <div className='frame_col'><Link to={`${s.season}`}>{<p className='seasonheadline'>Clubs</p>}</Link></div>             
          <div className='frame_col'><Link to={`${s.season}/album`}>{<p className='seasonheadline'>Album</p>}</Link></div>          
        </div> */
        <div className='special_frame'>       
        <div><Link to={`${s.season}`}>{<p className='compname frame_fontsize'>{s.season}</p>}</Link></div>             
      </div>
        )
    }

    return(
      <div className='mainbody'>         
        <div className='header_comps'>
          <div className="row">
              <div className='col_width'> 
                <img className='club_logo' src={complogo} alt='club logo'/>                
              </div>
              <div className='col_width comp_div'>  
                  <p className='compname_seasons'>{compname}</p>               
              </div>    
              <div className='col_width'>
                <img className='flag_logo' src={compflag} alt='flag'/>                
              </div>
          </div>
        </div>
        <div className='outer_frame'>
          {renderSeason()}
        </div>     
      </div>     
      
    )  
  
  };

export default CompetitionDetails;