import React, { useEffect, useState} from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import {useRef, useContext} from 'react'
import '../Styles/Navbar.css'
import {Link, useNavigate} from 'react-router-dom'
import AuthContext from '../Contexts/AuthProvider';
import axios from 'axios';
import logo from '../Styles/logo_schmal.svg';

function NavbarComp(props) {

    const navRef = useRef(); 
    axios.defaults.withCredentials = true;
    let verified; 
    let commonDB;

    const {auth, setAuth} = useContext(AuthContext);
    const nav = useNavigate();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    const logout = () => {
        axios.post("https://sorare-album.com/public/logout");   
        nav('/');     
        setTimeout(()=>{
        window.location.reload(true)  
        }, 500);
        getUserData();
    }

    const getUserData = () => {
        axios.get("https://sorare-album.com/public/login").then(
            (response) => {             
            if (response.data.loggedIn == true) {
                if(response.data.verified == 'True'){
                    verified = true               
                }else if(response.data.verified == "False"){
                    verified = false     
                }   
                if(response.data.common == 1){
                    commonDB = true               
                  }else if(response.data.common == 0){
                    commonDB = false     
                  }   
                setAuth({
                    user: response.data.user, 
                    loggedIn: true,
                    rarity: response.data.rarity, 
                    common: response.data.common,
                    role: response.data.role,
                    verified: verified,  
                    level: response.data.level,
                    common: commonDB,
                    mail: response.data.mail,
                    slug: response.data.slug,
                    sorareID: response.data.sorareID,
                    access: response.data.accessToken
                })        
            }
            else{
                setAuth({loggedIn: false})
            }
        });
    }

    useEffect(() => {
        getUserData();
    }, []);  
    
    return (
        <header>
            <h3><Link to="/"><img src={logo} className="logo"/></Link></h3>
            <nav ref={navRef}>
            <Link onClick={showNavbar} to="/">Home</Link>  
            <Link onClick={showNavbar} to="/competitions">Football</Link>             
            <Link onClick={showNavbar} to="/special-album">Special cards</Link>  
            <Link onClick={showNavbar} to="/nba">NBA</Link> 
            <Link onClick={showNavbar} to="/mlb">MLB</Link>    

            {auth.loggedIn ? 
            <>
            {/* <Link onClick={showNavbar} to="/owned">Library</Link> */}
            <Link onClick={showNavbar} to="/account">Profile: {auth.mail}</Link>
            <Link onClick={logout} to="/">Logout</Link>
            </>
            : 
            <>
            <Link onClick={showNavbar} to="/login">Login</Link>
            <Link onClick={showNavbar} to="/register">Register</Link>
            </>}
         
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes/>
            </button>  
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars/>
            </button>
        </header>
    ); 
}

export default NavbarComp;
