import React, {useState, useEffect} from 'react'
import '../Styles/Competitions.css'
import {Link, useParams} from 'react-router-dom'
import logo from '../Styles/nologo_160.png'

function SpecialEditionSeasons() {
    
    var params = useParams();
    let edition = params["editionslug"]
    
    const [data, setData] = useState([])

    useEffect(() => {
        fetch("https://sorare-album.com/public/get/specialedition").then(
          res => res.json()
        ).then(
          data =>{
            setData(data)
          }
        )
        window.scrollTo(0, 0);
      }, [])

      var found = data.filter(function(item) { return item.editionslug == edition; });

    const renderTeams = () => {
        found.forEach(element => {    
            if( element['editionslug'] != "Champion Edition"){
                element['link'] = <div><Link to={`/special-album/${element.editionslug}/${element.season}`}><p className='compname'>{element.season}</p></Link></div>       
            }
            else{
                element['link'] = <div><Link to={`/special-album/${element.editionslug}/${element.season}/teams`}><p className='compname'>{element.season}</p></Link></div>     
            }   
        });
            
        return found.map(comp =>
        <div className='special_frame'>       
            {comp.link}
        </div>)
    }

    return(
        <div className='mainbody'>
          <p className='headline_comp bold'>Special edition cards</p>
          <div className='outer_frame'>
            {renderTeams()}
          </div>
        </div>          
    );
}

export default SpecialEditionSeasons;
