import React, {useState} from 'react';
import FAQ from '../Material/FAQ';
import "../Styles/Help.css";

const Help = () => {

    const [faqs, setfaqs] = useState([
        {
            question: 'What is Sorare Album?',
            answer: 'Sorare Album is a platform focused on the collecting aspect of Sorare. You can view all cards from the licensed soccer leagues, NBA, MLB and special editions. You can get an overview of cards and see which cards are still missing in your collection. You can also create albums for individual clubs or an entire league. Then you can download individual pages or an entire album as a PDF file. You can share it with your friends or print it out. So you can hold your Sorare album in your hand.',
            open: false
        },
        {
          question: 'Do I have to connect my Sorare Account to use Sorare Album?',
          answer: 'To experience the full functionality of Sorare Album you need to connect your account. This is the only way to display your cards in the albums. Without the account connection you can still view the empty albums and the map overviews.',
          open: false
        },
        {
          question: 'What access will Sorare Album have to my Sorare account when I connect it?',
          answer: 'Sorare Album is granted read-only rights via OAuth. Sorare Album can access your username and your cards. We do not get access to create lineups or buy or sell cards. It\'s just like Soraredata.',
          open: false
        },
        {
          question: 'How much does Sorare Album cost?',
          answer: <p>For the time being, the use is free of charge. Sorare Album is financed through affiliate links to Sorare. This means that Sorare Album receives a commission when a card is purchased via the button in the card overview. Of course, there are no additional costs for the buyer. You are also welcome to support us through donations. See more on this page: <a href="/support">Support</a></p>,
          open: false
        },
        {
            question: 'What can I do if my question has not been addressed here?',
            answer: <p>You can send a message via the <a href="/contact">contact formular</a>.</p>,
            open: false
        }
      ]);
    
      const toggleFAQ = index => {
        setfaqs(faqs.map((faq, i) => {
          if (i === index) {
            faq.open = !faq.open
          } else {
            faq.open = false;
          }
    
          return faq;
        }))
      }
    
    
      return (      
        <div className="mainbody">
            <p className='headline_comp'>Frequently asked questions</p>
            <div className="faqs">
                {faqs.map((faq, i) => (
                    <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
                ))}
            </div>
        </div>
      );
};

export default Help;