import { useRef, useState, useEffect , useContext} from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  axios from "axios";
import AuthContext from '../Contexts/AuthProvider';
import React from "react";
import '../Styles/User.css';
import { Alert, AlertTitle } from '@mui/material/';

const MAIL_REGEX = /^\S+@\S+\.\S+$/;

const ChangeMail = () => {
    const userRef = useRef();
    const errRef = useRef();

    const { auth, setAuth } = useContext(AuthContext);    
    const mail = auth.mail;

    let verifiedDB;

    const [verified, setVerified] = useState();

    const [validName, setValidName] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);
    
    const [newMail, setNewMail] = useState('');
    const [validNewMail, setValidNewMail] = useState(false);
    const [newMailFocus, setNewMailFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [regStatus, setRegStatus] = useState("");
    const [success, setSuccess] = useState(false);

    const [matchMail, setMatchMail] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
  
    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(MAIL_REGEX.test(mail));
    }, [mail])
    
    useEffect(() => {
        setValidNewMail(MAIL_REGEX.test(newMail));
    }, [newMail])
    
    useEffect(() => {
        setValidMatch(mail != newMail);
    }, [matchMail])

    useEffect(() => {
        setErrMsg('');
    }, [mail, newMail, matchMail])

    useEffect(() => {
        getUserData();
    }, [success]);

    const getUserData = () => {
        axios.get("https://sorare-album.com/public/login").then(
            (response) => {             
            if (response.data.loggedIn == true) {
                if(response.data.verified == 'True'){
                    verifiedDB = true               
                }else if(response.data.verified == "False"){
                    verifiedDB = false     
                }   
                setAuth({
                    user: response.data.user, 
                    loggedIn: true,
                    rarity: response.data.rarity, 
                    common: response.data.common,
                    role: response.data.role,
                    verified: verifiedDB,  
                    level: response.data.level,
                    mail: response.data.mail,
                    slug: response.data.slug,
                    userID: response.data.userID,
                    access: response.data.accessToken
                })        
            }
            else{
                setAuth({loggedIn: false})
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = MAIL_REGEX.test(mail);
        const v2 = MAIL_REGEX.test(newMail);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        } 

        axios.post("https://sorare-album.com/public/changeMail", {
                email: mail,
                newmail: newMail,
                withCredentials: true,
                }).then((response) => {    
                setRegStatus(response.data.message);
                if(response.status == 201){    
                    getUserData();
                    setSuccess(true);
                }
        })
    }       

    return (
        <div>
            {success ? (
                <div className="register">
                <section>
                <><Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                Your E-Mail address has been changed. — <strong>You'll receive an e-mail to your new address to verify that address.</strong>
                </Alert><br></br></>
                </section>
                </div>
            ) : (
            <div className="changepw">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Change mail</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">
                            Current E-Mail:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !mail ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            ref={userRef}
                            value={mail}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setMailFocus(true)}
                            onBlur={() => setMailFocus(false)}
                            disabled
                        />
                        <p id="uidnote" className={mailFocus && mail && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must be a valid e-mail address.
                        </p>
                        
                        <label htmlFor="username">
                            New E-Mail:
                            <FontAwesomeIcon icon={faCheck} className={validNewMail ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validNewMail || !newMail ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setNewMail(e.target.value)}
                            value={newMail}
                            required
                            aria-invalid={validNewMail ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setNewMailFocus(true)}
                            onBlur={() => setNewMailFocus(false)}
                        />
                        <p id="uidnote" className={newMailFocus && newMail && !validNewMail ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must be a valid e-mail address.
                        </p>
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must be different from current E-Mail address.
                        </p>

                        {regStatus ? 
                        <>
                        <br></br>
                        <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {regStatus}
                        </Alert>
                        </> 
                        : <></>}

                        {/* <p className={matchFocus}>
                            {regStatus}
                        </p> */}

                        <button className='buttonreg' onClick={handleSubmit} disabled={!validName || !validNewMail ? true : false}>Update E-Mail</button>
                        {/*Change error message layout */}
                    </form>
                </section>
                </div>
            )}
        </div>
    )
}

export default ChangeMail;