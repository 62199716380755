import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './Contexts/AuthProvider';

import './Styles/About.css'
import './Styles/Album.css'
import './Styles/AlbumPDF.css'
import './Styles/Articles.css'
import './Styles/Club.css'
import './Styles/Competitions.css'
import './Styles/Essentials.css'
import './Styles/Footer.css'
import './Styles/Main.css'
import './Styles/Modal.css'
import './Styles/Navbar.css'
import './Styles/User.css'



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>   
    <AuthProvider>      
      <App/>
    </AuthProvider>
  </React.StrictMode>  
);

reportWebVitals();
