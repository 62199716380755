import React from 'react';
import '../Styles/About.css';
import cardpic from '../Styles/SorareCard.png';


const About = () => {
    return (
        <>
        <h1>About me</h1>
        <div class="item">            
            <div class="description">
                <div className='title_about'>Welcome to Sorare Album!</div>
                <div className='text'>                    
                    My name is Noah, I am the creator of this platform. At this point I want to tell you a bit about myself and about Sorare Album.Welcome to Sorare Album, a project that has grown out of my passion for Sorare.
                    <br></br>
                    <br></br>I am a 23-year-old guy from germany who started playing Sorare in October 2021 and was instantly hooked on the game. I still remember the moment when I received my first reward: Lars Stindl, the captain of Borussia Mönchengladbach. 
                    <br></br>As a collector of Sorare cards, I noticed that there was something missing from the experience. I wanted a way to showcase my collection and keep track of which cards I had and which ones I needed to complete cards of a club or even a league.
                    <br></br>
                    <br></br>As I searched for a solution, I realized that the concept of creating a digital album for Sorare cards was missing. This is when the idea of Sorare Album was born.
                    <br></br>My inspiration for Sorare Album came from the classic Panini albums that I used to collect as a child. I wanted to create a platform that could capture the same magic of collecting and showcase Sorare cards in a visually stunning way.
                    <br></br>
                    <br></br>Sorare Album is the result of my passion for Sorare cards and my desire to create a platform that could enhance the collectable aspect of the game. I am proud to offer a user-friendly and intuitive platform that can help Sorare collectors worldwide make the most of their collections.
                    <br></br>
                    <br></br>Thank you for choosing Sorare Album, and I look forward to helping you showcase your collection in the best possible way.
                    <br></br>
                    <br></br>PS: As a Sorare collector and football fan, my dream is to complete an album of Mainz 05 and Liverpool cards.
               </div>
            </div>
            <div class="featured-image">
                <a href="https://sorarescout.com/resources/sorare-card-maker/" target="_blank" rel="noopener noreferrer"><img src={'https://sorare-album.com/assets/SorareCard.png'} alt="about me picture"/> </a>
            </div>
        </div>
        </>  
    );
};

export default About;