import React from 'react';
import '../Styles/Essentials.css'

const Data = () => {
    return (
        <div className='essent-div-data'>
            <p className='headline_essential'><strong>Privacy Policy</strong></p>
            <p>Personal data (usually referred to just as „data“ below) will only be processed by us to the extent necessary and for the purpose of providing a functional and user-friendly website, including its contents, and the services offered there.</p><p>Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter referred to as the „GDPR“), „processing“ refers to any operation or set of operations such as collection, recording, organization, structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction performed on personal data, whether by automated means or not.</p>
            <p>The following privacy policy is intended to inform you in particular about the type, scope, purpose, duration, and legal basis for the processing of such data either under our own control or in conjunction with others. We also inform you below about the third-party components we use to optimize our website and improve the user experience which may result in said third parties also processing data they collect and control.</p><p>Our privacy policy is structured as follows:</p>
            <p className='content'>I. Information about us as controllers of your data</p>
            <p className='content'>II. The rights of users and data subjects</p>
            <p className='content'>III. Information about the data processing</p>
            
            <h3>I. Information about us as controllers of your data</h3>
            <p>The party responsible for this website (the „controller“) for purposes of data protection law is:</p>
            <p>Wagner, Noah
            <br/>Mainzerstraße 12
            <br/>55278, Mommenheim
            <br/>Phone: upon request
            <br/>E-Mail: info@sorare-album.com</p>

            <h3>II. The rights of users and data subjects</h3>
            <p>With regard to the data processing to be described in more detail below, users and data subjects have the right:</p>
            <ul> 
                <li>to confirmation of whether data concerning them is being processed, information about the data being processed, further information about the nature of the data processing, and copies of the data (cf. also Art. 15 GDPR);</li> 
                <li>to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);</li> 
                <li>to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;</li>
                <li>to receive copies of the data concerning them and/or provided by them and to have the same transmitted to other providers/controllers (cf. also Art. 20 GDPR);</li> <li>to file complaints with the supervisory authority if they believe that data concerning them is being processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).</li> 
            </ul>
            <p>In addition, the controller is obliged to inform all recipients to whom it discloses data of any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves a disproportionate effort. Nevertheless, users have a right to information about these recipients.</p>
            <p><strong>Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller’s future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data processing for the purpose of direct advertising is permissible.</strong></p>
            
            <h3>III. Information about the data processing</h3>
            <p>Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or unless otherwise stipulated below.</p>
            
            <h4 class="jet-listing-dynamic-field__content">1. Cookies</h4><h5>a) Session cookies</h5> <p>We use cookies on our website. Cookies are small text files or other storage technologies stored on your computer by your browser. These cookies process certain specific information about you, such as your browser, location data, or IP address.</p>
            <p>This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to display our website in different languages or to offer a shopping cart function.</p> <p>The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to collect data to initiate or process contractual relationships.</p>
            <p>If the processing does not serve to initiate or process a contract, our legitimate interest lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.</p> <p>When you close your browser, these session cookies are deleted.</p>
            <h5>b) Third-party cookies</h5>
            <p>If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of advertising, analyzing, or improving the features of our website.</p>
            <p>Please refer to the following information for details, in particular for the legal basis and purpose of such third-party collection and processing of data collected through cookies.</p>
            <h5>c) Disabling cookies</h5>
            <p>You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support. Browser settings cannot prevent so-called flash cookies from being set. Instead, you will need to change the setting of your Flash player. The steps and measures required for this also depend on the Flash player you are using. If you have any questions, please use the help function or consult the documentation for your Flash player or contact its maker for support.</p>
            <p>If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully usable.</p>
            
            <h4 class="jet-listing-dynamic-field__content">2. Contact</h4>
            <p>If you contact us via email or the contact form, the data you provide will be used for the purpose of processing your request. We must have this data in order to process and answer your inquiry; otherwise we will not be able to answer it in full or at all.</p>
            <p>The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.</p>
            <p>Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation to store your data, such as if an order or contract resulted therefrom.</p>
            
            <h4 class="jet-listing-dynamic-field__content">3. Customer account/registration</h4>
            <p>If you create a customer account with us via our website, we will use the data you entered during registration (e.g. your email address) exclusively for services leading up to your potential placement of an order or entering some other contractual relationship with us, to fulfill such orders or contracts, and to provide customer care (e.g. to provide you with an overview of your previous orders or to be able to offer you a wishlist function). We also store date and time of your registration. This data will not be transferred to third parties.</p>
            <p>During the registration process, your consent will be obtained for this processing of your data, with reference made to this privacy policy. The data collected by us will be used exclusively to provide your customer account.</p>
            <p>If you give your consent to this processing, Art. 6 Para. 1 lit. a) GDPR is the legal basis for this processing.</p>
            <p>If the opening of the customer account is also intended to lead to the initiation of a contractual relationship with us or to fulfill an existing contract with us, the legal basis for this processing is also Art. 6 Para. 1 lit. b) GDPR.</p>
            <p>You may revoke your prior consent to the processing of your personal data at any time under Art. 7 Para. 3 GDPR with future effect. All you have to do is inform us that you are revoking your consent.</p>
            <p>The data previously collected will then be deleted as soon as processing is no longer necessary. However, we must observe any retention periods required under tax and commercial law.</p>
                        
            <h4 class="jet-listing-dynamic-field__content">4. Sorare Account connection</h4>
            <p>You can connect to your Sorare account in your account settings.If you do, you will be redirected to Sorare. There you log in and accept via a pop-up window whether you agree with the transfer of the data mentioned in the pop-up. As said, you can see in the pop-up exactly what you grant access to and what not. When you accept, we receive a token with which we request your Sorare ID. We store this ID and use it to query, for example, which cards you own in order to display them to you.</p>

            <h4 class="jet-listing-dynamic-field__content">5. Server data</h4>
            <p>For technical reasons, the following data sent by your internet browser to us or to our server provider will be collected, especially to ensure a secure and stable website: These server log files record the type and version of your browser, operating system, the website from which you came (referrer URL), the webpages on our site visited, the date and time of your visit, as well as the IP address from which you visited our site.</p>
            <p>The data thus collected will be temporarily stored, but not in association with any other of your data.</p>
            <p>The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the improvement, stability, functionality, and security of our website.</p>
            <p>The data will be deleted within no more than seven days, unless continued storage is required for evidentiary purposes. In which case, all or part of the data will be excluded from deletion until the investigation of the relevant incident is finally resolved.</p>
            
            <h4 class="jet-listing-dynamic-field__content">6. BootstrapCDN</h4>
            <p>We use the service BootstrapCDN, a content delivery network (“CDN”), on our website to optimise the speed of retrieval, the design and presentation of the contents of our website on different devices.&nbsp; This is a service of StackPath, LLC, 2021 McKinney Ave.&nbsp; Suite 1100, Dallas, TX 75201, USA, hereinafter simply referred to as „BootstrapCDN“.</p>
            <p>In order to be able to deliver the content of our website quickly, the service uses so-called JavaScript libraries.&nbsp; Corresponding files are loaded from a BootstrapCDN server that records your IP address.&nbsp;</p> <p>At <a href="https://www.stackpath.com/legal/master-service-agreement/#privacy" rel="nofollow noopener" target="_blank">https://www.stackpath.com/legal/master-service-agreement/#privacy</a>&nbsp;&nbsp;</p>
            <p>Prospectone Sp.z.o.o. offers further data protection information.</p>
            <p>The legal basis is Art.&nbsp; 6 para. 1 lit. f) GDPR. Our legitimate interest lies in speeding up loading times and protecting our website, as well as in analysing and optimising our website.</p>
            <p>To totally prevent the execution of BootstrapCDN’s JavaScript code, you can install a so-called JavaScript blocker, such as noscript.net or ghostery.com.&nbsp; If you were to prevent or restrict the execution of the Java Script code, this can mean that for technical reasons not all the content and functions of our website may be available.</p>
            
            <h4 class="jet-listing-dynamic-field__content">7. FontAwesome</h4>
            <p>In order to be able to display fonts and visual elements of our website, we use FontAwesome’s external fonts.&nbsp; FontAwesome is a service of</p>
            <p>Fonticons Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA, hereinafter referred to as „FontAwesome“.</p>
            <p>When you access our website, a connection is established to the FontAwesome server in the USA in order to enable and update the display of fonts and visual elements.</p>
            <p>The legal basis is Art.&nbsp; 6 para. 1 lit. f) GDPR. Our legitimate interest lies in the optimisation and economic operation of our website.</p>
            <p>By connecting to the FontAwesome server when you access our website, FontAwesome can determine from which website your request was sent and to which IP address the font should be sent.</p>
            <p>At <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener nofollow">https://fontawesome.com/privacy</a></p>
            <p>FontAwesome offers further information, in particular on the options for preventing data use.</p>
        </div>
    );                                   
};

export default Data;