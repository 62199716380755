import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Club.css'
import { useParams, Link, useLocation} from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner'
import AuthContext from '../Contexts/AuthProvider';

function NBATeams(props) {

    const location = useLocation();

    const auth = useContext(AuthContext);    
    const rarity = auth.auth.rarity
    const userID = auth.auth.sorareID 
    const common = String(auth.auth.common);

    var [ownedCards] = useState(0);
    var slugwallet = [];

    var params = useParams();
    const slug = params["teamslug"]
    var [loading, setLoading] = useState(false)
    var [data, setData] = useState([])
    var [wallet, setWallet] = useState([])
    var [loadingWallet, setLoadingWallet] = useState(true)

    var [division, setDivision] = useState("")
    var [clubname, setClubName] = useState("")
    var [clubimg, setClubImg] = useState("")    
    var [clubColor, setClubColor] = useState("")

    useEffect(() => {
      const fetchTeam = async () => {
      if(location.state != undefined){
        setDivision(location.state.team.division)
        setClubName(location.state.team.name);
        setClubImg(location.state.team.pictureUrl);
        setClubColor(location.state.team.color);
      } else {
        await fetch(`https://sorare-album.com/public/get/nbateam/${slug}`).then(
          res => res.json()
        ).then(
          team =>{
              setDivision(team.division)
              setClubName(team.name)
              setClubImg(team.pictureUrl)
              setClubColor(team.color)              
          })   
      }
      }
      window.scrollTo(0, 0);
      fetchTeam()
    }, []) 
    
    useEffect(() => {
      const fetchData = async () => {
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/nba/${slug}`).then(
          res => res.json()
        ).then(
          data =>{
            setData(data)   
            data.forEach((p, index) =>{
              if(p.avatarImageUrl==""){            
                delete data[index] 
              }
            }) 
            setLoading(true)       
          }
        )      
      }
      
      fetchData()
    }, [slug]);    

    useEffect(() => {
      const fetchCards = async () => {
      if(userID != undefined && rarity != undefined && common != undefined){
      await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/nbaCardsById/${rarity}&${userID}&${common}`).then(
        res => res.json()
      ).then(
        data =>{
          data.forEach(w =>{
            if(w.team.slug == slug){
              slugwallet.push([w.player.displayName, w.fullImageUrl, w.player.lastName, w.player.firstName])
            }
          }) 
        })
        setWallet(slugwallet)
        setLoadingWallet(false)
      } else {
        setLoadingWallet(false)
      }
    }
    fetchCards()
    }, [userID, rarity, common]);          
    
    //Karten nach Positionen sortieren
    const importOrder = ["NBA_GUARD", "NBA_CENTER", "NBA_FORWARD"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    data = data.sort(function(left, right) {
        return sortByObject[left.positions] - sortByObject[right.positions];
    })

    const hoehe = "150"

    var pics = []  

    data.map(k =>
      k.positions= k.positions.toString().replace(/NBA_/g, ' '))
    data.map(k =>         
      k.birthDate= k.birthDate.toString().slice(0, 10)) 
  
    data.forEach(p => {   
      if(rarity == "all" || rarity == undefined){  
      var link = "https://sorare.pxf.io/c/4295270/1590023/12209?u=https%3A%2F%2Fsorare.com%2Fnba%2Fplayers%2F" + p.slug + "%2Fcards"
      //"https://sorare.com/nba/players/" + p.slug + "/cards"        
      } else {
        var link = "https://sorare.pxf.io/c/4295270/1590023/12209?u=https%3A%2F%2Fsorare.com%2Fnba%2Fplayers%2F" + p.slug + "%2Fcards%3Frarity%3D" + rarity
      }    
      pics.push(<>   
      <div className='card_box'>                 
      <div className='inner_blanco'>         
          <p className='nameheadline_club'>{p.displayName}</p>
          <img className='imgnba' src={p.avatarImageUrl} height={hoehe} alt='Spielerfoto' />
          <p className='desc_club'>Position: {p.positions}</p>
          <p className='desc_club'>Birthdate: {p.birthDate}</p>
      </div>
      <p className='desc_club'><a href={link} target="_blank" rel="noopener noreferrer"><button className='findbutton'>Find on Sorare</button></a></p>
      </div>
      </>)
      
    })

    //put in owned cards
    data.forEach((p, index) =>{
      for(let i = 0; i < wallet.length; i++){
        if(p.displayName==wallet[i][0]){ 
          ownedCards = ownedCards+1;
          pics[index] = (<div className='card_box'> 
          <div className='inner_owned' key={wallet[i][0]}>
          <img src={wallet[i][1]} className="img_owned" alt='cardpic'/> 
          </div></div>)   
          break;
        }
      }
    })   

    const renderPages = () => {
      return pics.map(p => <div>{p}</div>)
    }

    return(
      <div className='mainbody'>
        {loading ?
        <> 
          <div className='header_americansports' style={{backgroundColor: clubColor}}>
          <div className="row">
              <div className='col_width'> 
                <img className='club_logo' src={clubimg} alt='club logo'/>                
              </div>
              <div className='col_width'>
                <div>         
                  <p className='clubname'>{clubname}</p>  
                  <p className='club_info'>Division: {division}</p>           
                </div>
              </div>
              <div className='col_width'>
                <div>
                  {loadingWallet ? 
                    <p className='clubname'>Owned cards:  
                    <ColorRing
                    visible={true}
                    height="25"
                    width="25"          
                    wrapperStyle={{}}
                    colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
                    />/{pics.length}</p>            
                    :
                    <p className='clubname'>Owned cards: {ownedCards}/{pics.length}</p>            
                	}  
                  <Link to={`album`}  state={{ data: data, wallet: wallet }}> <button className='button_album' style={{backgroundColor: clubColor}}>See Album</button></Link>  
                </div>
              </div>
          </div>
        </div>

        <div className='outer_cards'>
            {renderPages()}
        </div>
        </>
        :
        <div className='loading_spinner'>
          <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
          />
        </div>}
      </div>
    )
};

export default NBATeams;