import React, {useContext} from 'react';
import "./Styles/Main.css"
import AuthContext from './Contexts/AuthProvider';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import Home from './Components/Home';
import NavbarComp from './Essentials//Navbar';
import Footer from './Essentials/Footer';
import Contact from './Essentials/Contact';
import Impress from './Essentials/Impress';
import OwnedCards from './Components/OwnedCards';
import Competitions from './Components/Competitions';
import Album from './Components/Album';
import CompetitionDetails from './Components/CompetitionDetails';
import NoMatch from './Essentials/NoMatch';
import Season from './Components/Season';
import Club from './Components/Club';
import Data from './Essentials/Data';
import NBA from './Components/NBA';
import NBATeams from './Components/NBATeams';
import MLB from './Components/MLB';
import MLBTeams from './Components/MLBTeams';
import AlbumComp from './Components/AlbumComp';
import Account from './Essentials/Account';
import EditionAlbums from './Components/SpecialEditions';
import Register from './Essentials/Register';
import Login from './Essentials/Login';
import SpecialCards from './Components/SpecialCards';
import Experiments from './Components/Experiments';
import SpecialEditionSeasons from './Components/SpecialEditionSeasons';
import SpecialTeams from './Components/SpecialTeams';
import SpecialCardsAlbum from './Components/SpecialCardsAlbum';
import AlbumPDF from './Components/AlbumPDF';
import About from './Essentials/About';
import Help from './Essentials/Help';
import Support from './Essentials/Support';
import AlbumCompPDF from './Components/AlbumCompPDF';
import NBAAlbum from './Components/NBAAlbum';
import MLBAlbum from './Components/MLBAlbum';
import SpecialCardsAlbumPDF from './Components/SpecialCardsAlbumPDF';
import NBAAlbumPDF from './Components/NBAAlbumPDF';
import MLBAlbumPDF from './Components/MLBAlbumPDF';
import MailVerification from './Essentials/MailVerification';
import Forgot from './Essentials/Forgot';
import ResetPassword from './Essentials/ResetPassword';
import Cookies from './Essentials/Cookies';
import Auth from './Essentials/Auth';

function App(){

  const {auth, setAuth} = useContext(AuthContext);

  return (
    <React.StrictMode>           
      <Router>
        <NavbarComp/> 
          <Routes>          
            <Route path="/"element={<Home/>}></Route>
            {auth.loggedIn ? <Route path="/owned"element={<OwnedCards/>}></Route> : <></> }
            <Route path="/competitions"element={<Competitions/>}></Route>
            <Route path="/nba"element={<NBA/>}></Route>
            <Route path="/mlb"element={<MLB/>}></Route>
            <Route path="/album"element={<Album/>}></Route>
            <Route path="/special-album"element={<EditionAlbums/>}></Route>
            <Route path="/contact"element={<Contact/>}></Route>           
            {auth.loggedIn ? <Route path="/account"element={<Account/>}></Route> : <></> } 
            {/* <Route path="/account"element={<Account/>}></Route>  */}

            <Route path="/login"element={<Login/>}></Route>
            <Route path="/register"element={<Register/>}></Route>
            <Route path="/forgot"element={<Forgot/>}></Route>
            <Route path="/reset/:hash_mail/:token"element={<ResetPassword/>}></Route>
            <Route path="/about"element={<About/>}></Route>
            <Route path="/help"element={<Help/>}></Route>
            <Route path="/support"element={<Support/>}></Route>
            <Route path="/impress"element={<Impress/>}></Route>
            <Route path="/dataprivacy"element={<Data/>}></Route>
            <Route path="/cookie-policy"element={<Cookies/>}></Route>
            <Route path="/verify/:user/:code"element={<MailVerification/>}></Route>

            
            {auth.loggedIn ? <Route path="/auth/callback"element={<Auth/>}></Route> : <></>}

            <Route path="/experiments"element={<Experiments/>}></Route>
            
            <Route path="/nba/:teamslug" element={<NBATeams/>}/>
            <Route path="/nba/:teamslug/album" element={<NBAAlbum/>}/>
            <Route path="/nba/:teamslug/album/pdf" element={<NBAAlbumPDF/>}/>
            <Route path="/mlb/:teamslug" element={<MLBTeams/>}/>
            <Route path="/mlb/:teamslug/album" element={<MLBAlbum/>}/>
            <Route path="/mlb/:teamslug/album/pdf" element={<MLBAlbumPDF/>}/>

            <Route path="/competitions/:compslug" element={<CompetitionDetails/>}/>
            <Route path="/competitions/:compslug/:season" element={<Season/>}/>
            <Route path="/competitions/:compslug/:season/:club" element={<Club/>}/>
            
            <Route path="/special-album/:editionslug"element={<SpecialCards/>}></Route> 

            <Route path="/special-album/:editionslug/seasons"element={<SpecialEditionSeasons/>}></Route>
            <Route path="/special-album/:editionslug/:season"element={<SpecialCards/>}></Route>
            <Route path="/special-album/:editionslug/:season/album"element={<SpecialCardsAlbum/>}></Route>
            <Route path="/special-album/:editionslug/:season/album/pdf"element={<SpecialCardsAlbumPDF/>}></Route>

            <Route path="/special-album/:editionslug/:season/teams"element={<SpecialTeams/>}></Route>
            <Route path="/special-album/:editionslug/:season/:team"element={<SpecialCards/>}></Route>
            <Route path="/special-album/:editionslug/:season/:team/album"element={<SpecialCardsAlbum/>}></Route>
            <Route path="/special-album/:editionslug/:season/:team/album/pdf"element={<SpecialCardsAlbumPDF/>}></Route>
            
            <Route path="/special-album/:editionslug/album"element={<SpecialCardsAlbum/>}></Route>
            <Route path="/special-album/:editionslug/album/pdf"element={<SpecialCardsAlbumPDF/>}></Route>

            <Route path="/competitions/:compslug/:season/album" element={<AlbumComp/>}/>
            <Route path="/competitions/:compslug/:season/album/pdf" element={<AlbumCompPDF/>}/>
            <Route path="/competitions/:compslug/:season/:club/album" element={<Album/>}/>
            <Route path="/competitions/:compslug/:season/:club/album/pdf" element={<AlbumPDF/>}/>
            
            <Route path="/*" element={<NoMatch/>}></Route>          
          </Routes>
        </Router>
        <CookieConsent
          location="bottom"
          buttonText="Ok, I understand"
          style={{ background: "#29335c",}}
          buttonStyle={{color: "#29335c", background: "#ffffff", fontSize: "14px", borderRadius: "15px" }}
          expires={150}
        >
          <strong>About cookies on this website</strong>
          <br></br>
          This website uses technical necessary cookies only. Displaying resources from Sorare, third-party cookies are set from Sorare. See the<a href="/cookie-policy" className='foot-link'>cookie policy</a> for more information.
        </CookieConsent>
      <Footer/>
    </React.StrictMode>
  );
}

export default App;