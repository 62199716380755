import React, {useState, useEffect} from 'react'
import '../Styles/Competitions.css'
import { useParams} from 'react-router-dom';
import {Link} from 'react-router-dom'
import logo from '../Styles/nologo_160.png'
import { FcApproval, FcCancel } from "react-icons/fc";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Season() {

  var params = useParams();
  
  const slug = params["compslug"]
  const season = params["season"]

  var [data, setData] = useState([])
  var [rankAvailable, setRank] = useState(false)

  useEffect(() => {
      fetch(`https://sorare-album.com/public/get/clubs/${slug}/${season}`).then(
          res => res.json()
        ).then(data =>{
          setData(data)
          if(data[0]['rank'] != null){ setRank(true) }
        })
        window.scrollTo(0, 0);
  }, [])
    
  data.forEach(testa =>{   
      if(testa.pictureUrl==""){
        testa.pictureUrl = logo
    }})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  data.forEach(club => {
    if(club['cardsprinted'] == "True"){
      club['cardsprinted'] = <FcApproval size={"35px"}/>    
      club['cardsboolean'] = true    
    }
    else if(club['cardsprinted'] == "False"){
      club['cardsprinted'] = <FcCancel size={"35px"}/>
      club['cardsboolean'] = false
    }
  })

  if(rankAvailable == false){
    const sortByRank = [true, false]
      .reduce((obj, item, index) => {
          return {
          ...obj,
          [item]: index,
          };
      }, {});

      //Teams nach Cardsprinted    
      data = data.sort(function(left, right){
          return sortByRank[left['cardsboolean']] - sortByRank[right['cardsboolean']];
      })
  } 

  return (
    <div className='mainbody'>
    <TableContainer component={Paper}>
      <Table sx={{ width: "98%", marginLeft: "1%", marginRight: "1%"}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Logo</StyledTableCell>
            <StyledTableCell>Club</StyledTableCell>
            <StyledTableCell>Cards</StyledTableCell>
            <StyledTableCell>Rank</StyledTableCell>
            <StyledTableCell>Points</StyledTableCell>
            <StyledTableCell>Won</StyledTableCell>
            <StyledTableCell>Draw</StyledTableCell>
            <StyledTableCell>Lost</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell>{row.cardsboolean ? <Link to={`${row.clubslug}`}><img className='tablelogo' src={row.pictureUrl} alt='competition-logo' /></Link> : <img className='tablelogo' src={row.pictureUrl} alt='competition-logo' />}</StyledTableCell>
              <StyledTableCell>{row.cardsboolean ? <Link to={`${row.clubslug}`}><p className='tablefont'>{row.clubname}</p></Link>: <p className='tablefont'>{row.clubname}</p>}</StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.cardsprinted}</p></StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.rank}</p></StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.points}</p></StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.matchesWon}</p></StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.matchesDrawn}</p></StyledTableCell>
              <StyledTableCell><p className='tablefont'>{row.matchesLost}</p></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
    
}

export default Season;