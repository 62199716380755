import { useRef, useState, useEffect, useContext } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from '../Contexts/AuthProvider';
import  axios from "axios";
import React from "react";
import '../Styles/User.css';
import { Alert, AlertTitle } from '@mui/material/';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%?]).{8,24}$/;

const ChangePassword = () => {
    const userRef = useRef();
    const errRef = useRef();

    const { auth } = useContext(AuthContext);    
    const mail = auth.mail; 

    const [htmlMessage, setHTMLMessage] = useState('');
    
    const [current_pwd, setCurrentPwd] = useState('');    
    const [currentValidPwd, setCurrentValidPwd] = useState(false);
    const [currentPwdFocus, setCurrentPwdFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [regStatus, setRegStatus] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setCurrentValidPwd(PWD_REGEX.test(current_pwd));
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [current_pwd, pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [current_pwd , pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = PWD_REGEX.test(current_pwd);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        } 
        
        axios.post("https://sorare-album.com/public/changePW", {
                mail: mail, 
                currentpw: current_pwd,
                password: pwd,
                withCredentials: true,
                }).then((response) => {    
                setRegStatus(response.data.message);
                if(response.status == 201){
                    setSuccess(true)
                }
        })
    }       

    return (
        <div>
            {success ? (
                <div className="changepw">
                <section>
                <><Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                Your password has been changed.
                </Alert><br></br></>
                </section>
                </div>
            ) : (
            <div className="changepw">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Change password</h1>
                    <form onSubmit={handleSubmit}>
                    <label htmlFor="current_password">
                            Current Password:
                            <FontAwesomeIcon icon={faCheck} className={currentValidPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={currentValidPwd || !current_pwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setCurrentPwd(e.target.value)}
                            value={current_pwd}
                            required
                            aria-invalid={currentValidPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setCurrentPwdFocus(true)}
                            onBlur={() => setCurrentPwdFocus(false)}
                        />
                        <p id="pwdnote" className={currentPwdFocus && !currentValidPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                        <label htmlFor="password">
                            New password:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                        />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                        <label htmlFor="confirm_pwd">
                            Confirm new password:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>

                        <button className='buttonreg' onClick={handleSubmit} disabled={!validPwd || !validMatch ? true : false}>Change password</button>
                        {/*Change error message layout */}
                        {regStatus ? 
                        <>
                        <br></br>
                        <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {regStatus}
                        </Alert>
                        </> 
                        : <></>}
                    </form>
                </section>
                </div>
            )}
        </div>
    )
}

export default ChangePassword;