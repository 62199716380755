import React from 'react';

const NoMatch = () => {
    return (
        <div className='mainbody'>
            <p className='headline_comp bold'>No match for this URL</p>
            <br></br>
            <p className='nomatch_text'>The page you're looking for doesn't exist. Click <a href='/'>here</a> to return to home.</p>
            <br></br>
            <img src={"https://sorare-album.com/assets/missed_goal.png"} className='mainpic'/>
        </div>
    );
};

export default NoMatch;