import React, { useCallback, useRef, useContext } from 'react';
import"../Styles/Album.css";
import {useState, useEffect} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom';
import logo from '../Styles/nologo_160.png';
import { toPng } from 'html-to-image';
import AuthContext from '../Contexts/AuthProvider';
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { Alert, AlertTitle } from '@mui/material/';

const NBAAlbum = (props) => {
  
  const location = useLocation();

  const auth = useContext(AuthContext);   

  console.log(auth)
  console.log(auth.sorareID)
  console.log(auth.auth.sorareID)

  const rarity = auth.auth.rarity
  const userID = auth.auth.sorareID
  const common = String(auth.auth.common)
  
  var params = useParams();
  const slug = params["teamslug"]

  let [changedrarity, setRarity] = useState();
  var [data, setData] = useState([])
  const [wallet, setWallet] = useState([])
  var [currentPage, setCurrentPage] = useState(0);
  var [activePage, setActivePage] = useState(1)
  var slugwallet = []  
  var [clubname, setClubName] = useState("")

  useEffect(() => {
    if(location.state == undefined){
    fetch(`https://sorare-album.com/cgi-bin/backend.cgi/nba/${slug}`).then(
      res => res.json()
    ).then(
      data =>{
        setData(data)   
        data.forEach((p, index) =>{
          if(p.avatarImageUrl==""){            
              delete data[index] 
          }
        })   
        setClubName(data[0].team.fullName)
      }
    )} else {
        setData(location.state.data)
        setClubName(location.state.data[0].team.fullName)
    }
    window.scrollTo(0, 0);
  }, [slug]); 

  useEffect(() => {
    const fetchWallet = async () => {
    if(rarity != undefined && userID != undefined && common != undefined && location.state == undefined){
      await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/nbaCardsById/${rarity}&${userID}&${common}`).then(
        res => res.json()
      ).then(
        wallet =>{
          wallet.forEach(w =>{
            if(w.team.slug == slug){
              slugwallet.push([w.player.displayName, w.fullImageUrl, w.player.lastName, w.player.firstName])
            }
          })
        })
        setWallet(slugwallet)
    }
    else if (location.state != undefined){
      setWallet(location.state.wallet)
    }
  }
  fetchWallet()
  }, [rarity, userID, common]); 

  //Karten nach Positionen sortieren
  const importOrder = ["NBA_GUARD", "NBA_CENTER", "NBA_FORWARD"];

  const sortByObject = importOrder
  .reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  data = data.sort(function(left, right) {
    return sortByObject[left.positions] - sortByObject[right.positions];
  })

  const hoehe = "150"

  var pics = []  

  data.map(k =>
    k.positions= k.positions.toString().replace('NBA_', ' '))
  data.map(k =>         
    k.birthDate= k.birthDate.toString().slice(0, 10)) 

  data.forEach((p, index) => { 
    pics.push(<>                                  
        <div className='inner_card'>         
            <p className='nameheadline'>{p.displayName}</p>
            <img className='imgplayer' src={p.avatarImageUrl} alt='Spielerfoto' />
            <p className='desc'>Position: {p.positions}</p>
            <p className='desc'>Birthdate: {p.birthDate}</p>
        </div>    
    </>)    
  })

  data.forEach((p, index) =>{
    for (let i = 0; i < wallet.length; i++){
      if(p.displayName==wallet[i][0]){ 
        /* ownedCards = ownedCards+1; */
        pics[index] = (<div className='inner_limited'> 
       <img src={wallet[i][1]} className="imgcard" alt='Spielerfoto'/> 
        </div>)   
        break;
      }  
    }
  }) 

  const c = Math.ceil(pics.length/9)
  const pages = []
  
  for (let index = 0; index < c; index++) {
    pages.push(pics.slice(9*index,(index+1)*9)) 
  }

  const ref = useRef(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
        return
    }

    toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
        const link = document.createElement('a')   
        const docname = slug.toString()
        link.download = docname
        link.href = dataUrl
        link.click()
        })
        .catch((err) => {
        })
  }, [ref])

  const renderPages = (numb) => {
      return <div className='outer_box' style={{backgroundImage: `url('https://sorare-album.com/assets/basket.svg')`}}>{pages[numb]}</div>
  }
    
  const changePage = ({selected}) => {
      setCurrentPage(selected)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }

  return(
    <>
      <p className='headline_comp'>{clubname}</p>
      <Link to={`pdf`} state={{ data: data, wallet: wallet }}><p className='headline'Album><button className='main_button' text-align="center" justify-content="center">Create PDF from album</button></p></Link>
      <p className='headline'><button className='main_button' text-align="center" justify-content="center" onClick={onButtonClick}>Download page as PNG</button></p>
      <div className='info_alert'>
        <Alert variant="outlined" severity="info">
          <AlertTitle>Info</AlertTitle>
          Currently we're experiencing problems with the PNG and PDF downloads on apple devices.
          <br></br>
          We're sorry for the inconvenience. For now you can download the pictures and PDF's on other devices correctly. We're working on a solution for the problem.
        </Alert>
      </div>
      <div className='paginationDiv'>
        <Pagination
            currentPage={activePage}
            itemsPerPage={1}
            totalItems={pages.length}          
            onPageChange={handlePageChange.bind(this)}
            pageNeighbours={0}
            withGoToInput={true}
            startLabel={1}
            endLabel={pages.length}
            nextLabel={'Next'}
            prevLabel={'Prev'}
            customClassNames={{
              rpbItemClassName:'custom-item',
              rpbItemClassNameActive:'custom-item--active',
              rpbGoItemClassName: 'custom-go-item',
              rpbItemClassNameDisable: 'custom-item--disable', 
              rpbProgressClassName: 'custom-progress-bar',
              rpbRootClassName: 'custom-root',
            }}
        />
      </div>
      <div ref={ref}>
        {renderPages(activePage-1)}
      </div>
      <p></p>      
    </>
  )

}

export default NBAAlbum;