import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material/';

const MailVerification = () => {

    const [verified, setVerified] = useState()

    var params = useParams();
    const user = params["user"]
    const code = params["code"]

    useEffect(() => {
        axios.get(`https://sorare-album.com/public/verify/${user}/${code}`).then(
            (response) => {
            if (response.data.verified == true) {
                setVerified(true)
            }
            else{
                setVerified(false)
            }
        });
    }, []);  

    return (
        <div className='mainbody'>
            <p className='headline'>Account verification</p>
            <div className='verificationDiv'>
            {verified ? 
            <>                
            <Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                Your account has been verified. 
            </Alert><br></br>
            </>            
            :
            <>
            <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            The verification code is invalid or expired. <br></br> <strong> You can request a new one in your profile page when you're logged in.</strong>
            </Alert><br></br>
            </>        
            }
            </div>
            <Link to={`/login`}><p className='headline'Album><button className='main_button' text-align="center" justify-content="center">Sign in</button></p></Link>
            
        </div>
    );
};

export default MailVerification;