import React, { useCallback, useRef, useContext } from 'react';
import"../Styles/Album.css";
import {useState, useEffect} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom';
import logo from '../Styles/nologo_160.png';
import { toPng } from 'html-to-image';
import { jsPDF } from 'jspdf';
import { Alert, AlertTitle } from '@mui/material/';

import AuthContext from '../Contexts/AuthProvider';
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";

import { CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import * as htmlToImage from 'html-to-image';

const MLBAlbumPDF = (props) => {

    const location = useLocation();

    const auth = useContext(AuthContext);    
    const rarity = auth.auth.rarity
    const userID = auth.auth.sorareID
    const common = String(auth.auth.common);
    
    var params = useParams();
    const slug = params["teamslug"]
  
    let [changedrarity, setRarity] = useState();
    var [data, setData] = useState([])
    const [wallet, setWallet] = useState([])
    var [currentPage, setCurrentPage] = useState(0);
    var [activePage, setActivePage] = useState(1)
    var slugwallet = []  

    var [clubname, setClubName] = useState("")
  
    var [creatingPDF, setCreatingPDF] = useState(false)    
    var loading=true;

    useEffect(() => {
      if(location.state == undefined){
      fetch(`https://sorare-album.com/cgi-bin/backend.cgi/mlb/${slug}`).then(
        res => res.json()
      ).then(
        data =>{
          setData(data)    
          data.forEach((p, index) =>{
            if(p.avatarImageUrl==""){            
                delete data[index] 
            }
          })   
          setClubName(data[0].team.fullName)
        }
      )} else {
          setData(location.state.data)
          setClubName(location.state.data[0].team.fullName)
      }
      window.scrollTo(0, 0);
    }, [slug]); 
  
    useEffect(() => {
      const fetchWallet = async () => {
      if(rarity != undefined && userID != undefined && common != undefined && location.state == undefined){
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/mlbCardsById/${rarity}&${userID}&${common}`).then(
          res => res.json()
        ).then(
          wallet =>{
            wallet.forEach(w =>{
              if(w.team.slug == slug){
                slugwallet.push([w.player.displayName, w.fullImageUrl, w.player.lastName, w.player.firstName])
              }
            })
          })
          setWallet(slugwallet)
      }
      else if (location.state != undefined){
        setWallet(location.state.wallet)
      }
    }
    fetchWallet()
    }, [rarity, userID, common]); 

    //Karten nach Positionen sortieren
    const importOrder = ["STARTING_PITCHER", "RELIEF_PITCHER", "FIRST_BASE", "THIRD_BASE", "DESIGNATED_HITTER", "CATCHER", "SECOND_BASE", "SHORTSTOP", "OUTFIELD"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});
  
    data = data.sort(function(left, right) {
      return sortByObject[left.positions] - sortByObject[right.positions];
    })
  
    const hoehe = "150"
  
    var pics = []  
  
    data.map(k =>
      k.positions= k.positions.toString().replace('NBA_', ' '))
    data.map(k =>         
      k.birthDate= k.birthDate.toString().slice(0, 10)) 
  
    data.forEach(p => { 
      pics.push(<>                                  
          <div className='inner_card_pdf'>         
              <p className='nameheadline_pdf'>{p.displayName}</p>
              <img className='imgplayer_pdf' src={p.avatarImageUrl} alt='Spielerfoto' />
              <p className='desc_pdf'>Position: {p.positions}</p>
              <p className='desc_pdf'>Birthdate: {p.birthDate}</p>
          </div>    
      </>)      
    });

    data.forEach((p, index) =>{
      for (let i = 0; i < wallet.length; i++){
        if(p.displayName==wallet[i][0]){ 
          //ownedCards = ownedCards+1;
          pics[index] = (<div className='inner_limited_pdf'>
            <img src={wallet[i][1]} className='imgcard_pdf' alt='Spielerfoto'/></div>)   
          break;
        }  
      }
    })
  
    const c = Math.ceil(pics.length/9)
    const pages = []
    
    for (let index = 0; index < c; index++) {
      pages.push(pics.slice(9*index,(index+1)*9)) 

      if(index == pages.length-1){
        loading = false;          
      } 
    }
  
    const ref = useRef(null)
  
    const onButtonClick = useCallback(() => {
      if (ref.current === null) {
          return
      }
  
      toPng(ref.current, { cacheBust: true, })
          .then((dataUrl) => {
          const link = document.createElement('a')   
          const docname = slug.toString()
          link.download = docname
          link.href = dataUrl
          link.click()
          })
          .catch((err) => {
          })
    }, [ref])
  
    const printRef = React.useRef();

    const renderPages = () => {
        return pages.map(p => <div className='outer_box_pdf' style={{backgroundImage: `url('https://sorare-album.com/assets/baseball.svg')`}}>{p}</div>)
    }

    async function exportMultipleChartsToPdf() {
        const doc = new jsPDF(); // (1)

        const elements = document.getElementsByClassName("outer_box_pdf"); // (2)     
        /* for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'visible'            
        } */
        setCreatingPDF(true)

        await creatPdf({ doc, elements }); // (3-5)

       /*  for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'hidden'            
        } */
        setCreatingPDF(false)

        const name = `${clubname}.pdf`;
        
        doc.save(name); // (6)
    }

    var [pagecounter, setPageCounter] = useState(0);

    async function creatPdf({doc, elements}) {          
        var i = 0;
        while (i < elements.length) {
            const el = elements.item(i);
            //Bild erscheinen lassen
            //el.style.visibility = 'visible'   
            const imgData = await htmlToImage.toJpeg(el, {cacheBust: true});  
            
          

            //Umgehen, dass erste Seite leer ist
            if(i != 0){
            doc.addPage();
            }

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 0, width, height);  
            //Bild wieder verstecken
            //el.style.visibility = 'hidden'  
            i = i+1;      
            setPageCounter(i);      
        }
    }

      
  
    return(
        <><div className='mainbody'>
        <p className='headline_comp'>{clubname}</p>
        {loading ? 
            <p className='headline'>Loading the album...</p> 
            : 
            <>
            <div className='info_alert'>
                <Alert variant="outlined" severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Currently we're experiencing problems with the PDF downloads on apple devices.
                    <br></br>
                    We're sorry for the inconvenience. For now you can download PDF's on other devices correctly. We're working on a solution for the problem.
                </Alert>
            </div>
            <p className='headline'>
                <button className='main_button' type="button" text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
                    Download as PDF
                </button>
            </p>
            </>
        }
        <div>
        {creatingPDF ? <p className='headline'>Your PDF is being generated.</p> : <p></p>}
        <div className='loading_animation'><CircularProgressbar value={(pagecounter/pages.length)*100} text={`${(pagecounter/pages.length)*100} %`}/></div>
        </div>
        <div className='offscreen' style={{top: `-${pages.length * 29.7}cm`}}>{renderPages()}</div>
        </div>
        </>
    )
  
};

export default MLBAlbumPDF;