import React from 'react';
import AuthContext from '../Contexts/AuthProvider';
import '../Styles/Articles.css';
import logo from '../Styles/Sorarebild.svg';
import player from '../Styles/player.png';
import choose from '../Styles/choose.png';
import album from '../Styles/album.png';
import share from '../Styles/share.png';


function Home() {

  return (
    <>    
      <h1>Welcome to Sorare Album!</h1>      
      <img src={logo} className="mainpic" alt='picture of top leagues'/>
      <h1>What is Sorare Album?</h1>
      <h2>Sorare Album is the ultimate tool for Sorare collectors!</h2>
        
      <div className='container_features'>
        <br></br>
        <h2>Here are just a few reasons why our platform is a must-have for any serious collector:</h2>
        <br></br>
        <ul className='features'>
          <li className='features_individual'>Enhance the collectibility aspect of Sorare: Sorare Album was designed to make collecting Sorare cards even more enjoyable and engaging.</li>
          <li className='features_individual'>Keep track of missing cards: With the club view, you can easily see which cards are missing from your collection, so you can focus on completing your sets.</li>
          <li className='features_individual'>Digital albums for all leagues and clubs: We've got you covered no matter which team or league you're into. Sorare Album supports all clubs and leagues that are licensed by Sorare.{/* , including the NBA and the MLB.*/}</li>
          <li className='features_individual'>Special edition cards: We know how important special edition cards are to Sorare collectors, which is why we make it easy to track which ones are missing from your gallery.</li>
          <li className='features_individual'>Share your collection: With Sorare Album, you can share your gallery and albums with friends, family, and other collectors.</li>
        </ul>
        <br></br>
      </div>
      <h1>How does it work?</h1>
      <div className='block_colored'>
        <div className='box'>
          <h2>Create an account</h2>
          <hr></hr>
          <img src={"https://sorare-album.com/assets/player.png"} className='centerpic'/>
          <hr></hr>
          <h3>Go to register and create an account. After that connect your Sorare account, so that we can show the cards that you own.</h3>          
        </div>
        <div className='box'>
          <h2>Choose a collection</h2>
          <hr></hr>
          <img src={"https://sorare-album.com/assets/choose.png"} className='centerpic'/>
          <hr></hr>
          <h3>Choose football, NBA, MLB or special cards in the Navbar. From there you can navigate to seasons, leagues and clubs.</h3>   
        </div>
        <div className='box'>
          <h2>Check out your collection</h2>
          <hr></hr>
          <img src={"https://sorare-album.com/assets/album.png"} className='centerpic'/>
          <hr></hr>
          <h3>See the cards you own and the ones you are missing. From there you can buy your missing cards.</h3>   
        </div>
        <div className='box'>
          <h2>Share your collection</h2>
          <hr></hr>
          <img src={"https://sorare-album.com/assets/share.png"} className='centerpic'/>
          <hr></hr>
          <h3>You can generate pictures or PDF's of your collection. You can share them on social media or print them out for you.</h3>   
        </div>
      </div>
      <hr></hr>
    </>
  )

}

export default Home;