import React, { useContext, useState, useEffect} from 'react';
import"../Styles/Album.css";
import"../Styles/Competitions.css";
import { useParams, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import ReactPaginate from 'react-paginate'
import axios from "axios";
import { Alert, AlertTitle } from '@mui/material/';

const Auth = () => {

    const { auth, setAuth } = useContext(AuthContext);    
    const mail = auth.mail;
  
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")

    let verifiedDB;
    let commonDB;

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const getUserData = () => {
        axios.get("https://sorare-album.com/public/login").then(
            (response) => {             
            if (response.data.loggedIn == true) {
                if(response.data.verified == 'True'){
                  verifiedDB = true               
                }else if(response.data.verified == "False"){
                  verifiedDB = false     
                }   
                if(response.data.common == 1){
                  commonDB = true               
                }else if(response.data.common == 0){
                  commonDB = false     
                }   
                setAuth({
                    user: response.data.user, 
                    loggedIn: true,
                    rarity: response.data.rarity, 
                    common: commonDB,
                    role: response.data.role,
                    verified: verifiedDB,  
                    level: response.data.level,
                    mail: response.data.mail,
                    slug: response.data.slug,
                    sorareID: response.data.sorareID
                })        
            }
            else{
                setAuth({loggedIn: false})
            }
        });
    } 

    useEffect(() => {
        if(code != undefined){
            axios.post("https://sorare-album.com/public/authWithId", {
            code: code,
            mail: auth.mail,
        }).then((response) => {
            if(response.status == 200){
                setSuccess(true)
                getUserData()
            } else if (response.status == 201 || response.status == 202){
                setError(true)
            }
        })
        }       
    }, [code, mail]);   
           
    return(  
        <div className='mainbody'> 
        <p className='headline_comp bold'>Connect your account with Sorare</p>        

        {success ? 
            <div className='info_alert'>
            <br></br>
            <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
                You have successfully connected your account with Sorare. Now can you see your cards in the club overview and the albums. <br></br>
                Also, you can see the status of the connection with Sorare in your account settings. 
            </Alert>
            </div> 
            : <></>
        }
        {error ? 
            <div className='info_alert'>
            <br></br>
            <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We are currently having a problem. Try again later or send a mail to our <a href='mailto:support@sorare-album.com'><button className='supportbutton support_text'>support</button></a>. 
            </Alert>
            </div> 
            : <></>
        }
        </div>
    );
};

export default Auth;
