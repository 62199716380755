import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link} from 'react-router-dom';
import axios from "axios";
import React from "react";
import '../Styles/User.css';
import { Alert, AlertTitle } from '@mui/material/';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%?]).{8,24}$/;

const ResetPassword = () => {

    const userRef = useRef();
    const errRef = useRef();

    var params = useParams();
    const hash_mail = params["hash_mail"]
    const token = params["token"]

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [regStatus, setRegStatus] = useState("");

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v2 = PWD_REGEX.test(pwd);
        if (!v2) {
            setErrMsg("Invalid Entry");
            return;
        } 
        
        axios.post(`https://sorare-album.com/public/pwreset/${hash_mail}/${token}`, {
            password: pwd,
            }).then((response) => {    
            if(response.status == 201){
                setSuccess(true)
            } else {
                setRegStatus(response.data.message);
                setError(true)
            }            
        })
    }       


    return (
        <div className="mainbody">      
            {success ? 
                <div className="register">
                    <section>
                    <><Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Your password has been changed. — <strong><a href="login">Sign In</a></strong>
                    </Alert><br></br></>
                    </section>
                </div>
                :      
                <div className="register">
                    <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        <h1>Reset password</h1>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="password">
                                Password:
                                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                            </label>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                8 to 24 characters.<br />
                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                            </p>

                            <label htmlFor="confirm_pwd">
                                Confirm Password:
                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                            </label>
                            <input
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                            />
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                Must match the first password input field.
                            </p>

                            {error ?
                            <>
                            <br></br>
                            <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {regStatus}
                            </Alert>
                            </>   
                            :
                            <></>                      
                            }

                            <button className='buttonreg' onClick={handleSubmit} disabled={!validPwd || !validMatch ? true : false}>Reset</button>
                            {/*Change error message layout */}
                            
                        </form>
                    </section>
                </div>
            }
        </div>    
    );
};

export default ResetPassword;