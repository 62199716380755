import React from 'react';
import {useState, useEffect, useContext} from 'react'
import '../Styles/Club.css'
import { useParams} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { ColorRing } from 'react-loader-spinner'

function OwnedCards() {

  
    const { auth } = useContext(AuthContext);    
    const userID = auth.sorareID 

    var [loading, setLoading] = useState(false)
    var [data, setData] = useState([])

    useEffect(() => {
      if(userID != undefined){
      fetch(`/pagcards/${userID}`).then(
        res => res.json()
      ).then(
        data =>{
          setData(data)
          setLoading(true)     
        }
      )}
      window.scrollTo(0, 0);
    }, [userID])

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    data = data.sort(function(left, right) {
      return sortByObject[left.node.position] - sortByObject[right.node.position]})
    
    const renderCards = () => {
      return data.map(p => 
      <div className='card_box'>
        <div className='inner_owned'>
          <img className='img_owned' src={p.node.pictureUrl} alt='Spielerfoto'/> 
        </div>
    </div>
    )}   
   
    return(
      <div className='mainbody'>
        {loading ? 
        <div className='outer_cards'>
            {renderCards()}
        </div>
        :
        <div className='loading_spinner'>
          <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
          />
        </div>}
      </div>   
    );
}
  

export default OwnedCards;