import React, {useEffect} from "react";
import "../Styles/Modal.css";
import { useState } from 'react';
import '../Styles/User.css';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material/';

function ModalDelete({ setOpenModal }) {
  
  const [mail, setMail] = useState();
  const [htmlMessage, setHTMLMessage] = useState('');
  
  const [pwd, setPwd] = useState('');  
  const [status, setStatus] = useState(''); 
  const [delButton, setDelButton] = useState(true); 
  const nav = useNavigate();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);


  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios.get("https://sorare-album.com/public/login").then((response) => {
      if (response.data.loggedIn == true) {
        setMail(response.data.mail)
      }
    });
  }, []);

  const deleteAcc = async (e) => {

    e.preventDefault();
    axios.post("https://sorare-album.com/public/delete", {
      email: mail,
      password: pwd,
      mailmessage: htmlMessage,
    }).then((response) => {
      if (response.data.message) {
        setStatus(response.data.message);    
        if(response.data.message == "deletion succesful"){           
          setDelButton(false)
          setSuccess(true)
        } 
        else{
          setError(true)
        }
      }
    });
  };

  const closeModal = () => {
    if(delButton == false){
      setOpenModal(false)
      nav('/');     
      window.location.reload(true)  
    } else {
      setOpenModal(false)
    }
  }

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1>Are you sure you want to delete your account?</h1>
        </div>
        <div className="body">
          <p className="text">Please insert your password and click on delete.</p>
        </div>
        <div className="body">
          <p>E-Mail address: {mail}</p>
        </div>
        <div className="body">
          <p><input type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required/>
          </p>
        </div>
        {success ?
          <><Alert onClose={() => setSuccess(false)} severity="success">
              <AlertTitle>Success!</AlertTitle>
              Your account has been deleted.
          </Alert><br></br></>
          : 
          <></>
        }
        {error ?
          <><Alert onClose={() => setError(false)} severity="error">
              <AlertTitle>Error!</AlertTitle>
              {status}
          </Alert><br></br></>
          : 
          <></>
        }

        {delButton ?
        <div className="footer">
          <button  onClick={() => {closeModal()}} id="cancelBtn">
            Cancel
          </button>
          <button onClick={deleteAcc}>Delete</button>
        </div> 
        : 
        <button  onClick={() => {closeModal();}} id="cancelBtn">Close</button>}

      </div>
    </div>
  );
}

export default ModalDelete;