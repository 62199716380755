import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Club.css'
import { useParams, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';

function SpecialTeams() {

  
    const { auth } = useContext(AuthContext);    
    const rarity = auth.rarity

    var params = useParams();
    let edition = params["editionslug"]
    let season = params["season"]

    var [data, setData] = useState([])
    const [wallet, setWallet] = useState([])

    useEffect(() => {
      fetch(`https://sorare-album.com/public/get/edition/teams/${edition}`).then(
        res => res.json()
      ).then(        
        data =>{
            setData(data)    
        }
      )
      window.scrollTo(0, 0);
    }, []);    

    if(season != undefined){
      data = data.filter(function(item) { return item.season == season; });
    }
   
    var pics = [] 

    const renderTeams = () => {
      return data.map(comp =>
        <div className='special_frame'>       
          <div><Link to={`/special-album/${edition}/${season}/${comp.teamname}`}><p className='compname'>{comp.teamname}</p></Link></div>
        </div>)
    }
  
    return(
      <div className='mainbody'>
        <p className='headline_comp bold'>Special edition cards</p>
        <div className='outer_frame'>
          {renderTeams()}
        </div>
      </div> 
    ) 
};

export default SpecialTeams;