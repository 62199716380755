import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Club.css'
import { useParams, Link} from 'react-router-dom';
import logo from '../Styles/nologo_160.png';
import AuthContext from '../Contexts/AuthProvider';
import { ColorRing } from 'react-loader-spinner'
import { common } from '@mui/material/colors';

function Club() {

    const { auth } = useContext(AuthContext);    
    const rarity = auth.rarity
    const userID = auth.sorareID 
    const common = String(auth.common);

    //TEST
    //Pawel: 54a5b139-2ce7-40ba-aca9-94e91fc30c5d
    //Nowag: 2f4d1934-f020-4c31-a19c-9a1014e75fe7
   /*  const userID = "54a5b139-2ce7-40ba-aca9-94e91fc30c5d";
    const rarity = "limited"; */

    var params = useParams();
    const season = params["season"]
    let club = params["club"]
    
    var [ownedCards] = useState(0);
    var [clubCards, setClubCards] = useState(0);

    var [loading, setLoading] = useState(false)
    var [loadingWallet, setLoadingWallet] = useState(true)
    var [data, setData] = useState([])
    var [clubname, setClubName] = useState("")
    var [clubimg, setClubImg] = useState("")
    var [clubfounded, setClubFounded] = useState("")
    const [wallet, setWallet] = useState([])     
    var slugwallet = []

    useEffect(() => {
      const fetchData = async () => {
      await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${club}`).then(
        res => res.json()
      ).then(
        data =>{
          setData(data)     
          setClubCards(data.length)
          if(data[0].team.pictureUrl == undefined){
            setClubImg(data[0].player.country.flagUrl)
          } else {
          setClubImg(data[0].team.pictureUrl)     
          }
          setClubName(data[0].team.name)     
          setClubFounded(data[0].team.founded)   
          setLoading(true)  
        }
      )}

      fetchData();
      window.scrollTo(0, 0);
    }, [season, club]);    

    useEffect(() => {
      const fetchWallet = async () => {
      if(rarity != undefined && userID != undefined && common != undefined){
      await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${club}&${rarity}&${userID}&${common}`).then(
        res => res.json()
      ).then(
        wallet =>{
          wallet.forEach(w =>{
            if(w.node.season.startYear == season){
              slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
            }
        })
      })
      setWallet(slugwallet)   
      setLoadingWallet(false)
      } else {
        setLoadingWallet(false)
      }
    }

    fetchWallet()

    }, [rarity, userID, common]);

    //check if cards are available for that season   
    if(data.result=="none"){      
      return(
      <div className='centered'>
        <img src={logo} alt='nocards'/>        
        <p className='headline'>There were no cards printed for that year.</p>
      </div>
      );
    };

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    //Zusätzlich Karten nach Appearances sortieren
    data = data.sort(function(left, right) {
      var city_order = sortByObject[left.positionTyped] - sortByObject[right.positionTyped];
      var price_order = parseInt(left.player.stats.appearances) - parseInt(right.player.stats.appearances);
      return city_order || -price_order;
    });

    var pics = [] 
    var link;

    data.forEach(p => {      
      //affiliate link to sorare
      if(auth.rarity == "all" || auth.rarity == undefined){
        link = "https://sorare.pxf.io/c/4295270/1590023/12209?u=https%3A%2F%2Fsorare.com%2Ffootball%2Fplayers%2F" + p.player.slug + "%2Fcards%3Fs%3DLowest%2BPrice%26season%3D" + season
        //link = "https://sorare.com/football/players/" + p.player.slug + "/cards?s=Lowest%20Price&season=" + season
      }
      else{
        link = "https://sorare.pxf.io/c/4295270/1590023/12209?u=https%3A%2F%2Fsorare.com%2Ffootball%2Fplayers%2F" + p.player.slug + "%2Fcards%3Frarity%3D" + auth.rarity + "%26season%3D" + season
        //link = "https://sorare.com/football/players/" + p.player.slug + "/cards?s=Lowest%20Price&rarity=" + auth.rarity + "&season=" + season
      }      
      pics.push(                    
        <>
        <div className='card_box'> 
        <div className='inner_blanco' key={p.player.displayName}> 
          <p className='nameheadline_club'>{p.player.displayName}</p>        
          <img className='imgplayerclub' src={p.pictureUrl} alt='Spielerfoto'/>        
          <p className='desc_club'>Position: {p.player.positionTyped}</p>
          <p className='desc_club'>Appearances: {p.player.stats.appearances}</p>
          <p className='desc_club'>Goals: {p.player.stats.goals} | Assists: {p.player.stats.assists}</p>
          <p className='desc_club'>Age {p.age}</p>
          <img className='imgflagclub' src={p.player.country.flagUrl} alt='flag'/>      
          {/* Einsetzen des Affiliate Links für Kartenkauf */}        
        </div>
        {/*<p className='desc_club'><button className='findbutton'><a href={link} target="_blank" rel="noopener noreferrer">Find on Sorare</a></button></p>*/}
        <p className='desc_club'><a href={link} target="_blank" rel="noopener noreferrer"><button className='findbutton'>Find on Sorare</button></a></p>
        </div></>
      ) 
    })

    data.forEach((p, index) =>{
      for (let i = 0; i < wallet.length; i++){
        if(p.player.displayName==wallet[i][0]){   
          ownedCards = ownedCards+1;
          pics[index] = (<div className='card_box'> 
          <div className='inner_owned' key={wallet[i][1]}>
          <img src={wallet[i][2]} className="img_owned" alt='Spielerfoto'/> 
        	</div></div>)   
          break;
        }        
      } 
    })   

    const renderPages = () => {
      return pics.map(p => <div key={p.name}>{p}</div>)
    }
    
    return(    
      <div className='mainbody'>
        {loading ? 
        <>
        <div className='header_comps'>
          <div className="row">
              <div className='col_width'> 
                <img className='club_logo' src={clubimg} alt='club logo'/>                
              </div>
              <div className='col_width'>
                <div>         
                  <p className='clubname'>{clubname}</p>          
                  <p className='club_info'>Season: {season}</p>             
                  <p className='club_info'>Founded: {clubfounded}</p>
                </div>
              </div>
              <div className='col_width'>
                <div>
                  {loadingWallet ? 
                  <p className='clubname'>Owned cards:  
                  <ColorRing
                  visible={true}
                  height="25"
                  width="25"          
                  wrapperStyle={{}}
                  colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
                  />/{clubCards}</p>            
                  :
                  <p className='clubname'>Owned cards: {ownedCards}/{clubCards}</p>            
                  }
                  <Link to={`album`}  state={{ data: data, wallet: wallet }}> <button className='button_album'>See Album</button></Link>  
                  
                </div>
              </div>
          </div>
        </div>

        <div className='outer_cards'>
          {renderPages()}
        </div>
        </>
        : 
        <div className='loading_spinner'>
          <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
          />
        </div>}
      </div>
    )
};

export default Club;