import React, { useCallback, useRef, useContext } from 'react';
import "../Styles/Album.css";
import {useState, useEffect} from 'react'
import { useParams, Link, useLocation } from 'react-router-dom';
import logo from '../Styles/nologo_160.png';
import { toPng } from 'html-to-image';
import AuthContext from '../Contexts/AuthProvider';
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { ColorRing } from 'react-loader-spinner';
import { Alert, AlertTitle } from '@mui/material/';

const Album = (props) => {
  
  const location = useLocation();

  const auth = useContext(AuthContext);   

  const rarity = auth.auth.rarity
  const userID = auth.auth.sorareID 
  const common = String(auth.auth.common);

   

  var params = useParams();
  const season = params["season"]
  const club = params["club"]

  let [changedrarity, setRarity] = useState();
  let [team, setTeam] = useState();
  var [data, setData] = useState([])
  const [wallet, setWallet] = useState([])
  var [currentPage, setCurrentPage] = useState(0);
  var [activePage, setActivePage] = useState(1)
  var loading = true;
  var [loadingWallet, setLoadingWallet] = useState(true)
  var slugwallet = []  

  useEffect(() => {
    const fetchData = async () => {
    if(location.state == undefined){
    await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${club}`).then(
      res => res.json()
    ).then(
      data =>{
        setData(data)  
        setTeam(data[0].team.name)        
      }
    )
    } else {
      setData(location.state.data)
      setTeam(location.state.data[0].team.name)
    }
  }
  fetchData()
  window.scrollTo(0, 0);
  }, [season, club]);    

  useEffect(() => {
    const fetchWallet = async () => {
    if(rarity != undefined && userID != undefined && common != undefined && location.state == undefined){
      await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${club}&${rarity}&${userID}&${common}`).then(
        res => res.json()
      ).then(
        wallet =>{
          console.log("test")
          wallet.forEach(w =>{
            if(w.node.season.startYear == season){
              slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
            }
          })
        })
        setWallet(slugwallet)
        setLoadingWallet(false)
    }
    else if (location.state != undefined){
      setWallet(location.state.wallet)
      setLoadingWallet(false)
    }
    else {
      setLoadingWallet(false)
    }
  }

  fetchWallet()
  }, [rarity, userID, common]); 

  console.log(wallet)

  //check if cards are available for that season   
  if(data.result=="none"){      
    return(
    <div className='centered'>
      <img src={logo} alt='nocards'/>        
      <p className='headline'>There were no cards printed for that year.</p>
    </div>
    );
  };

  //Karten nach Positionen sortieren
  const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

  const sortByObject = importOrder
  .reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  //Zusätzlich Karten nach Appearances sortieren
  data = data.sort(function(left, right) {
    var city_order = sortByObject[left.player.positionTyped] - sortByObject[right.player.positionTyped];
    var price_order = parseInt(left.player.stats.appearances) - parseInt(right.player.stats.appearances);
    return city_order || -price_order;
  });

  var pics = []  

  data.forEach(p => 
    pics.push(<>                    
    <div className='inner_card'>
        <p className='nameheadline'>{p.player.displayName}</p>
        <img className='imgplayer' src={p.pictureUrl} alt='Spielerfoto' />
        <p className='desc'>Appearances: {p.player.stats.appearances}</p>
        <p className='desc'>Goals: {p.player.stats.goals} | Assists: {p.player.stats.assists}</p>
        <p className='desc'>Position: {p.player.positionTyped}</p>
        <p className='desc'>Age {p.age}</p>
        <img className='imgflag' src={p.player.country.flagUrl} alt='Spielerfoto' />
    </div>
    </>)
  )

  var pow = "power"
  data.forEach((p, index) =>{
    for (let i = 0; i < wallet.length; i++){
      var pow = "power"
      if((((wallet[i][3])/0.005).toFixed(0))>=10){
        pow="powersilver"
      } 
      if((((wallet[i][3])/0.005).toFixed(0))==20){
        pow="powergold"
      } 
      if(p.player.displayName==wallet[i][0]){
        pics[index] = (<div className='inner_limited'>
        <img src={wallet[i][2]} className='imgcard' alt='Spielerfoto'/> 
        {/* show power level at bottom right if selected in profile */}        
{/*         <p className={pow}>LVL: {((slugwallet[i][3])/0.005).toFixed(0)}</p>
 */}        </div>)   
      }        
    }
    if(index == data.length-1){
      loading = false;
    }
  })   

  const c = Math.ceil(pics.length/9)
  const pages = []
  
  for (let index = 0; index < c; index++) {
    pages.push(pics.slice(9*index,(index+1)*9)) 
  }

  const ref = useRef(null)

  const onButtonClick = useCallback(() => {
  if (ref.current === null) {
    return
  }

  toPng(ref.current, { cacheBust: true, })
    .then((dataUrl) => {
      const link = document.createElement('a')   
      const docname = club.toString() + "_" + season.toString()
      link.download = docname
      link.href = dataUrl
      link.click()
    })
    .catch((err) => {
    })
  }, [ref])

  const renderPages = (numb) => {
    return <div className='outer_box'>{pages[numb]} <p className='pagenumber'>{numb+1}</p></div>
  }
    
  const changePage = ({selected}) => {
      setCurrentPage(selected)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  }

  return(          
    <div className='mainbody'>
      <div>      
      {loading ? 
        <div className='loading_spinner'>
            <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
            /></div> : 
          <>
            <p className='headline_comp bold'>{team} - {season}</p>
            <Link to={`pdf`} state={{ data: data, wallet: wallet }}><p className='headline' Album><button className='main_button' text-align="center" justify-content="center">Create PDF from album</button></p></Link>
            <p className='headline'><button className='main_button' text-align="center" justify-content="center" onClick={onButtonClick}>Download page as PNG</button></p>
            <div className='info_alert'>
            <Alert variant="outlined" severity="info">
              <AlertTitle>Info</AlertTitle>
              Currently we're experiencing problems with the PNG and PDF downloads on apple devices.
              <br></br>
              We're sorry for the inconvenience. For now you can download the pictures and PDF's on other devices correctly. We're working on a solution for the problem.
            </Alert>
            </div>
          </>      
      }
      </div>
      <div className='paginationDiv'>           
        <Pagination
          currentPage={activePage}
          itemsPerPage={1}
          totalItems={pages.length}          
          onPageChange={handlePageChange.bind(this)}
          pageNeighbours={0}
          withGoToInput={true}
          startLabel={1}
          endLabel={pages.length}
          nextLabel={'Next'}
          prevLabel={'Prev'}
          customClassNames={{
            rpbItemClassName:'custom-item',
            rpbItemClassNameActive:'custom-item--active',
            rpbGoItemClassName: 'custom-go-item',
            rpbItemClassNameDisable: 'custom-item--disable', 
            rpbProgressClassName: 'custom-progress-bar',
            rpbRootClassName: 'custom-root',
          }}
        />
      {!loading && loadingWallet ?
            <><ColorRing
            visible={true}
            height="40"
            width="40"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
            />
            <p>Loading owned cards.</p>
            </>
            : <p></p>}
      </div>
      <div ref={ref}>
        {renderPages(activePage-1)}
      </div>
    </div>
  )

};

export default Album;