import React, { useRef, useState, useContext, useEffect } from 'react';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import '../Styles/User.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from '../Contexts/AuthProvider';
import { Alert, AlertTitle } from '@mui/material/';

const MAIL_REGEX = /^\S+@\S+\.\S+$/;

const Forgot = () => {

    const userRef = useRef();
    const errRef = useRef();

    const [mail, setMail] = useState('');
    const [validName, setValidName] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);
    const [response, setResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setValidName(MAIL_REGEX.test(mail));
    }, [mail])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = MAIL_REGEX.test(mail);
        if (!v1) {
            setErrMsg("Invalid Entry");
            return;
        } 

        axios.post("https://sorare-album.com/public/pwreset", {
            email: mail,
            withCredentials: true,
            }).then((response) => {   
            if(response.status == 201){
                setSuccess(true)
            } else {
                setResponseMessage(response.data.message)
            }
        })
    }       

    return(
        <div className="mainbody">
            {success ? (
                <div className="register">
                <section>                    
                    <><Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    An E-Mail to reset your password has been sent. <br></br> <strong><a href="login">Sign In</a></strong>
                    </Alert></>                    
                </section>
                </div>
            ) : (
            <div className="register">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Forgot password</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">
                            E-Mail:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !mail ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setMailFocus(true)}
                            onBlur={() => setMailFocus(false)}
                        />
                        <p id="uidnote" className={mailFocus && mail && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must be a valid e-mail address.
                        </p>
                        
                        {responseMessage != '' ?                        
                        <>
                        <br></br>                        
                        <Alert severity="error">
                        <AlertTitle>Error!</AlertTitle>
                        {responseMessage}</Alert></>
                        :
                        <></>}

                        <button className='buttonreg' onClick={handleSubmit} disabled={!validName ? true : false}>Send reset mail</button>
                    </form>                   
                </section>
                </div>
            )}
        </div>
    )
};

export default Forgot;