import React, {useState, useEffect} from 'react'
import '../Styles/Competitions.css'
import {Link} from 'react-router-dom'
import logo from '../Styles/nologo_160.png'

function EditionAlbums() {
    
    const [data, setData] = useState([])

    useEffect(() => {
        fetch("https://sorare-album.com/public/get/specialedition").then(
          res => res.json()
        ).then(
          data =>{
            setData(data)
          }
        )
        window.scrollTo(0, 0);
      }, [])
    
      //remove duplicates 
      var clean = data.filter((data, index, self) =>
        index === self.findIndex((t) => (t.name == data.name)))

      const renderEditions = () => {
        clean.forEach(element => {          
          var found = data.filter(function(item) { return item.editionslug == element['editionslug']});
          element['seasons'] = found.length 
          
        if(element['editionslug'] == "national_series"){
          element['link'] =  <div><Link to={`${element.editionslug}/2022/teams`}><p className='compname frame_fontsize'>{element.name}</p></Link></div>            
        }        
        else if(element['seasons'] < 2){
          element['link'] =  <div className='comp_div'><Link to={`${element.editionslug}`}><p className='compname frame_fontsize'>{element.name}</p></Link></div>            
        }        
        else{
          element['link'] =  <div><Link to={`${element.editionslug}/seasons`}><p className='compname  frame_fontsize'>{element.name}</p></Link></div>    
        }   
      });
        
      return clean.map(comp =>
        <div className='special_frame'>       
          {comp.link}
        </div>)
      }
    
      return(
        <div className='mainbody'>
          <p className='headline_comp bold'>Special edition cards</p>
          <div className='outer_frame'>
            {renderEditions()}
          </div>
        </div>          
    );
}

export default EditionAlbums;
