import React, { useRef, useState, useContext, useEffect } from 'react';
import '../Styles/User.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from '../Contexts/AuthProvider';
import { Alert, AlertTitle } from '@mui/material/';

const Login = () => {

    const { setAuth } = useContext(AuthContext);
    
    const nav = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [mail, setMail] = useState('');
    /* const [rarity, setRarity] = useState(); */
    const [pwd, setPwd] = useState('');
    const [errMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loginStatus, setLoginStatus] = useState(''); 

    const login = async (e) => {
        e.preventDefault();
        axios.post("https://sorare-album.com/public/login", {
          email: mail,
          password: pwd,
          withCredentials: true,
        }).then((response) => {
            if (response.data.message) {
                //User doesn't exist
                setLoginStatus(response.data.message);
            } else {
                //successful login                          
                nav('/');                      
                window.location.reload(false)

                setLoginStatus("succesful login");  
                setSuccess(true)        
            }
        });
    };
   
    return (
        <div className='mainbody'>
            {success ? (
                <div className="register">
                <section>
                    <h1>You are logged in!</h1>
                    <br />
                    <p>
                        <a href="/">Go to Home</a>
                    </p>
                </section>
                </div>
            ) : (
            <div className="register">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Sign In</h1>
                    <form onSubmit={login}>
                        <label htmlFor="username">E-Mail:</label>
                        <input
                            type="text"
                            id="mail"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                            required
                        />

                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                        <span className="line">
                            <a className='link' href="forgot">Forgot password?</a>
                        </span>
                        <button className='buttonreg'>Sign In</button> 

                        {/* <div className={loginStatus ? "errmsg" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <p className=''>{loginStatus}</p>
                        </div>     */}   

                        {loginStatus ? 
                        <>
                        <br></br>
                        <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {loginStatus}
                        </Alert>
                        </> 
                        : <></>}
                    
                    </form>  
                        Need an Account?<br />
                        <span className="line">
                            <a className='link' href="register">Sign Up</a>
                        </span>
                   
                </section>
                </div>
            )}
        </div>
    )
}

export default Login;