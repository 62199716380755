import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Alert, AlertTitle } from '@mui/material/';
import '../Styles/User.css';
 
const MAIL_REGEX = /^\S+@\S+\.\S+$/;
//const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%?]).{8,24}$/;

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();

    const [htmlMessage, setHTMLMessage] = useState('');

    const [mail, setMail] = useState('');
    const [validName, setValidName] = useState(false);
    const [mailFocus, setMailFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [regStatus, setRegStatus] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(MAIL_REGEX.test(mail));
    }, [mail])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [mail, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = MAIL_REGEX.test(mail);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        } 

        setHTMLMessage(<>
        <div>
            <h1>Please verify your E-Mail address for Sorare Album</h1>
            <br></br>
            <p>Click on the following button to verify your e-mail:</p>
            <br></br>
            <button>Click here</button>
        </div>
        </>)
        
        axios.post("https://sorare-album.com/public/register", {
            email: mail,
            mailmessage: htmlMessage,
            password: pwd,
            withCredentials: true,
            }).then((response) => {    
            setRegStatus(response.data.message);
            if(response.status == 201){
                setSuccess(true)
            }
        })
    }       

    return (
        <div className="mainbody">
            {success ? (
                <div className="register">
                <section>
                <><Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                Your registration was succesful. — <strong><a href="login">Sign In</a></strong>
                </Alert><br></br></>
                </section>
                </div>
            ) : (
            <div className="register">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Register</h1>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">
                            E-Mail:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !mail ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            ref={userRef}
                            autoComplete="on"
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setMailFocus(true)}
                            onBlur={() => setMailFocus(false)}
                        />
                        <p id="uidnote" className={mailFocus && mail && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must be a valid e-mail address.
                        </p>


                        <label htmlFor="password">
                            Password:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                        />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                        <label htmlFor="confirm_pwd">
                            Confirm Password:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPwd(e.target.value)}
                            value={matchPwd}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>

                        <button className='buttonreg' onClick={handleSubmit} disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button>
                       

                        {/* <p className={matchFocus}>
                            {regStatus}
                        </p> */}
                        {regStatus ? 
                        <>
                        <br></br>
                        <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {regStatus}
                        </Alert>
                        </> 
                        : <></>}

                    </form>
                    <p>
                        Already registered?<br />
                        <span className="line">
                            <a className="link" href="/login">Sign In</a>
                        </span>
                    </p>
                </section>
                </div>
            )}
        </div>
    )
}

export default Register;