import React, { useCallback, useContext} from 'react';
import"../Styles/Album.css";
import"../Styles/AlbumPDF.css";
import"../Styles/Competitions.css";
import {useState, useEffect} from 'react'
import { useParams, Link, useLocation} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { jsPDF } from 'jspdf';
import { toPng, toJpeg } from 'html-to-image';
import * as htmlToImage from 'html-to-image';
import { Alert, AlertTitle } from '@mui/material/';


import { CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import html2canvas from "html2canvas";


const AlbumPDF = (props) => {

    const location = useLocation();

    const auth = useContext(AuthContext);    
    const rarity = auth.auth.rarity
    const userID = auth.auth.sorareID 
    const common = String(auth.auth.common);
  
    var params = useParams();
    const season = params["season"]
    const club = params["club"]

    var [teamname, setTeamName] = useState()
    var [teamimg, setTeamImg] = useState()
    var [teamfounded, setTeamFounded] = useState()

    var [user, setUser] = useState()
    var [username, setUsername] = useState("")
    var [userpicture, setUserPicture] = useState("")
    var [userbanner, setUserBanner] = useState("")
    var [usershield, setUserShield] = useState("")
    var [data, setData] = useState([])
    var [wallet, setWallet] = useState([])    
    var [creatingPDF, setCreatingPDF] = useState(false)  
    var [loadingWallet, setLoadingWallet] = useState(true)

    
    //both loadings need to be ready for the download (otherwise card count wouldn't be correct)
    var loading=true;
    var loadingCardCount=true;
   
    useEffect(() => {
        if(userID != undefined){
            fetch(`https://sorare-album.com/cgi-bin/backend.cgi/user/${userID}`).then(
                res => res.json()).then(user =>{
                    setUser(user)  
                    setUsername(user.nickname)                 
                    setUserPicture(user.pictureUrl)    
                    setUserBanner(user.profile.clubBanner.pictureUrl)             
                    setUserShield(user.profile.clubShield.pictureUrl)             
            })
        }
    }, [userID]);
    
    var players = []  
    var team = []  
    var slugwallet = []    

    useEffect(() => {
        const fetchData = async () => {
        if(location.state == undefined){
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${club}`).then(
          res => res.json()
        ).then(
          data =>{
            setData(data)         
            setTeamName(data[0].team.name) 
            setTeamImg(data[0].team.pictureUrl) 
            setTeamFounded(data[0].team.founded) 
          }
        )
        } else {
            setData(location.state.data)
            setTeamName(location.state.data[0].team.name) 
            setTeamImg(location.state.data[0].team.pictureUrl) 
            setTeamFounded(location.state.data[0].team.founded) 
        }
        }
        fetchData()
      }, [season, club]);    
    
    useEffect(() => {
        const fetchWallet = async () => {
        if(rarity != undefined && userID != undefined && common!= undefined && location.state == undefined){
            await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${club}&${rarity}&${userID}&${common}`).then(
                res => res.json()
            ).then(
                wallet =>{
                    wallet.forEach(w =>{
                        if(w.node.season.startYear == season){
                            slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
                        }                                        
                    })                      
                    setWallet(slugwallet)   
                    setLoadingWallet(false)                
                })
        } else if (location.state != undefined){
            setWallet(location.state.wallet)
            setLoadingWallet(false)
        } else {
            setLoadingWallet(false)
        }        
        }
        window.scrollTo(0, 0);
        fetchWallet()
    }, [rarity, userID]); 

    //top assister und golden_boot bestimmen
    var golden_boot = data.sort((a,b)=>b.player.stats.goals-a.player.stats.goals)[0]
    var golden_boot_stat;
    var golden_boot_pic;

    var top_assist = data.sort((a,b)=>b.player.stats.assists-a.player.stats.assists)[0]
    var top_assist_stat;    
    var top_assist_pic;

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
        return {
        ...obj,
        [item]: index,
        };
    }, {});

    //Zusätzlich Karten nach Appearances sortieren
    data = data.sort(function(left, right) {
        var city_order = sortByObject[left.player.positionTyped] - sortByObject[right.player.positionTyped];
        var price_order = parseInt(left.player.stats.appearances) - parseInt(right.player.stats.appearances);
        return city_order || -price_order;
    });
                      
    const pages = []
    var pics = []
    var cardscounter = 0;     

    data.forEach((p, idx) => {
        pics.push(<>                    
            <div className='inner_card_pdf'>
                <p className='nameheadline_pdf'>{p.player.displayName}</p>
                <img className='imgplayer_pdf' src={p.pictureUrl} height={"100px"} alt='Spielerfoto' />
                <p className='desc_pdf'>Appearances: {p.player.stats.appearances}</p>
                <p className='desc_pdf'>Goals: {p.player.stats.goals} | Assists: {p.player.stats.assists}</p>
                <p className='desc_pdf'>Position: {p.player.positionTyped}</p>
                <p className='desc_pdf'>Age {p.age}</p>
                <img className='imgflag_pdf' src={p.player.country.flagUrl} height="20px" alt='Spielerfoto' />
            </div>
            </>)
        if(p.player.displayName == golden_boot.player.displayName){golden_boot_pic = pics[idx]}       
        golden_boot_stat = golden_boot.player.stats.goals       
        if(p.player.displayName == top_assist.player.displayName){top_assist_pic = pics[idx]}  
        top_assist_stat = top_assist.player.stats.assists  
             
    })

    var pow = "power"

    data.forEach((p, index) =>{
        for (let i = 0; i < wallet.length; i++){
            var pow = "power"
            if((((wallet[i][3])/0.005).toFixed(0))>=10){
                pow="powersilver"
            } 
            if((((wallet[i][3])/0.005).toFixed(0))==20){
                pow="powergold"
            } 

            if(p.player.displayName==wallet[i][0]){
                cardscounter = cardscounter + 1;
                pics[index] = (<div className='inner_limited_pdf'>
                <img src={wallet[i][2]} className='imgcard_pdf' alt='Spielerfoto'/> 
                {/* show power level at bottom right if selected in profile */}        
        {/*         <p className={pow}>LVL: {((wallet[i][3])/0.005).toFixed(0)}</p>
        */}        </div>)  
                if(p.player.displayName == golden_boot.player.displayName){golden_boot_pic = <img src={wallet[i][2]} className='featured_owned_card_pdf' alt='Spielerfoto'/> }       
                if(p.player.displayName == top_assist.player.displayName){top_assist_pic = <img src={wallet[i][2]} className='featured_owned_card_pdf' alt='Spielerfoto'/> }       
                break; 
            }        
        }
        if(index == data.length-1){
            loadingCardCount = false;          
        }  
    })   

    const c = Math.ceil(pics.length/9)

    for (let index = 0; index < c; index++) {
        pages.push(pics.slice(9*index,(index+1)*9))   
        
        if(index == (c-1)){
            loading = false;          
        }  
    }      

    const renderFirstPage = () => {
        return( 
            <div>
                <p className='fontcover'>{teamname}</p>
                <img className='imgfblogo' src={teamimg} width="150" alt='clublogo'/>
                <p className='nameheadline_pdf'>founded: {teamfounded}</p>
                <p className='fontcover'>Season: {season}</p>

                {/*add clun info (add data from api call to database)*/}
                {/* <div className='clubdata_pdf'>
                    <div>
                        <p>Rank</p>
                        <p>15</p>
                    </div>
                    <div>
                        <p>Points</p>
                        <p>15</p>
                    </div>
                    <div>
                        <p>Games won</p>
                        <p>15</p>
                    </div>
                    <div>
                        <p>Games drawn</p>
                        <p>15</p>
                    </div>
                    <div>
                        <p>Games lost</p>
                        <p>15</p>
                    </div>
                </div> */}

                <div className='coverBackground_pdf'>                    
                    <div className='featured_card_box'>
                        <div className='featured_card_pdf'>{golden_boot_pic}</div>
                        <div className='featured_text'><p className='featured_text'>golden boot: {golden_boot_stat}</p></div>
                    </div>
                    <div className='featured_card_box'>
                        <div className='featured_card_pdf'>{top_assist_pic}</div>
                        <div className='featured_text'><p className='featured_text'>most assists: {top_assist_stat}</p></div>
                    </div>
                </div>

                <div className='manager_data'>                    
                    <div className='featured_text'><p className='featured_text'>Manager: {username}</p></div>
                    <div className='featured_text'><p className='featured_text'>Cards: {cardscounter}</p></div>
                    {userpicture != "" && userpicture != undefined ? <img className='imgflag' src={userpicture} alt='Userpicture'/> : <p></p>}
                    {/* {userbanner!=undefined ? <img className='imgflag' src={userbanner} alt='Userpicture'/> : <p></p>}
                    {usershield!=undefined ? <img className='imgflag' src={usershield} alt='Userpicture'/> : <p></p>}*/}
                </div>
            </div>
        )
    }
    
    pages.unshift(renderFirstPage())

    const printRef = React.useRef();

    const renderPages = () => {
        return pages.map((p, idx) => <div className='outer_box_pdf'>{p}{idx != 0 ? <p className='pagenumber_pdf'>{idx}</p> : <p></p>}</div>)
      
    }

    async function exportMultipleChartsToPdf() {
        const doc = new jsPDF(); // (1)

        const elements = document.getElementsByClassName("outer_box_pdf"); // (2)     
        /* for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'visible'            
        } */
        setCreatingPDF(true)

        await creatPdf({ doc, elements }); // (3-5)

       /*  for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'hidden'            
        } */
        setCreatingPDF(false)
        const name = `${teamname}_${season}.pdf`;
        doc.save(name); // (6)
    }

    var [pagecounter, setPageCounter] = useState(0);

    async function creatPdf({doc, elements}) {          
        var i = 0;
        while (i < elements.length) {
            const el = elements.item(i);
            //Bild erscheinen lassen
            //el.style.visibility = 'visible'   
            const imgData = await htmlToImage.toJpeg(el, {cacheBust: true});  

            //Umgehen, dass erste Seite leer ist
            if(i != 0){
            doc.addPage();
            }

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 0, width, height);  
            //Bild wieder verstecken
            //el.style.visibility = 'hidden'  
            i = i+1;      
            setPageCounter(i);      
        }
    }

    return(  
        <>
        {/* <p className='headline'>
        <button type="button"  text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
            Download as PDF
        </button>
        </p> */}
        <div className='mainbody'>
        <p className='headline_comp bold'>{teamname} - {season}</p>
            {loadingWallet ? 
                <p className='headline'>Loading the album...</p> 
                : 
                <>
                <div className='info_alert'>
                    <Alert variant="outlined" severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Currently we're experiencing problems with the PDF downloads on apple devices.
                        <br></br>
                        We're sorry for the inconvenience. For now you can download PDF's on other devices correctly. We're working on a solution for the problem.
                    </Alert>
                </div>
                <p className='headline'>
                    <button className='main_button' type="button" text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
                        Download as PDF
                    </button>
                </p>
                </>
            }
        <div>
            {creatingPDF ? <p className='headline'>Your PDF is being generated.</p> : <p></p>}
            <div className='loading_animation'><CircularProgressbar value={(pagecounter/pages.length)*100} text={`${(pagecounter/pages.length)*100} %`}/></div>
        </div>
        {/* put the pages above the standard screen */}
        <div className='offscreen' style={{top: `-${pages.length * 29.7}cm`}}>{renderPages()}</div>
        </div>
        </>
    );
};

export default AlbumPDF;