import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Album.css';
import '../Styles/Club.css';
import { useParams, useLocation, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { Alert, AlertTitle } from '@mui/material/';

function SpecialCardsAlbum(props) {

    const location = useLocation();
  
    const { auth } = useContext(AuthContext);    
    const rarity = auth.rarity
    const userID = auth.sorareID 

    var params = useParams();
    let edition = params["editionslug"]
    let season = params["season"]
    let team = params["team"]
    const [album, setAlbum] = useState(false);
    var [currentPage, setCurrentPage] = useState(0);
    var [activePage, setActivePage] = useState(1)

    var [editionname, setEditionName] = useState("")

    var [data, setData] = useState([])
    const [wallet, setWallet] = useState([])
    var slugwallet = []

    useEffect(() => {
      if(location.state == undefined){
        fetch(`https://sorare-album.com/public/get/edition/${edition}`).then(
          res => res.json()
        ).then(        
          data =>{
            setData(data)    
            setEditionName(data[0].name)
          }
        )
      } else {
        setData(location.state.data)
        setEditionName(location.state.data[0].name)
      }
      window.scrollTo(0, 0);
    }, []);
 
    useEffect(() => {
        if(rarity != undefined&& userID != undefined && location.state == undefined){
          fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userspecialcards/${edition}&${rarity}&${userID}`).then(
          res => res.json()
        ).then(
          wallet =>{
            wallet.forEach(w =>{
              if(season == undefined){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              } else if(w.node.season.startYear == season){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              }
            })
        })
        setWallet(slugwallet)          
        } else if (location.state != undefined){
          setWallet(location.state.wallet)
        }
    }, [edition, rarity]);
    
    //Bei manchen nach Mannschaft filtern      
    if(team != undefined){
      data = data.filter(function(item) { return item.teamname == team; });
    }

    //Falls vorhanden nach Saison filtern
    if(season != undefined){
      data = data.filter(function(item) { return item.season == season; });
    }

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    //Zusätzlich Karten nach Appearances sortieren
    data = data.sort(function(left, right) {
      var city_order = sortByObject[left.position] - sortByObject[right.position];
      return city_order
    });

    var pics = [] 

    data.forEach(p => {      
      pics.push(
      <>                    
      <div className='inner_card'>
          <p className='nameheadline'>{p.playername}</p>
          <img className='imgplayer' src={p.pictureUrl} alt='Spielerfoto' />
          <p className='desc'>Position: {p.position}</p>
          <p className='desc'>Age: {p.age}</p>
          {/* vorläufige Lösung für zu lange Schrift */}
          <p className='desc'>{p.teamname}</p>
          <p className='desc'>Season: {p.season}</p>
         <img className='imgflag' src={p.flagUrl} height="20px" alt='flag' />
     </div>
      </>
      )
    })

    data.forEach((p, index) =>{
      for (let i = 0; i < wallet.length; i++){
        if(p.playername==wallet[i][0]){
          pics[index] = (<div className='inner_limited'>
          <img src={wallet[i][2]} className="imgcard" alt='card'/> 
        	</div>)   
        }        
      }
    })    

    const c = Math.ceil(pics.length/9)
    const pages = []
    for (let index = 0; index < c; index++) {
      pages.push(pics.slice(9*index,(index+1)*9)) 
    } 
    
    const renderPages = (numb) => {
      return <div className='outer_box'>{pages[numb]}<p className='pagenumber'>{numb+1}</p></div>
    }

    const changePage = ({selected}) => {
      setCurrentPage(selected)
    }

    const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
    } 
    
    return(    
      <>
        <p className='headline_comp bold'>{editionname}</p>
        <Link to={`pdf`} state={{ data: data, wallet: wallet }}><p className='headline'Album><button className='main_button' text-align="center" justify-content="center">Create PDF from album</button></p></Link>
        <div className='info_alert'>
          <Alert variant="outlined" severity="info">
              <AlertTitle>Info</AlertTitle>
              Currently we're experiencing problems with the PDF downloads on apple devices.
              <br></br>
              We're sorry for the inconvenience. For now you can download PDF's on other devices correctly. We're working on a solution for the problem.
          </Alert>
        </div>
        <div className='paginationDiv'>
          <Pagination
            currentPage={activePage}
            itemsPerPage={1}
            totalItems={pages.length}          
            onPageChange={handlePageChange.bind(this)}
            pageNeighbours={0}
            withGoToInput={true}
            startLabel={1}
            endLabel={pages.length}
            nextLabel={'Next'}
            prevLabel={'Prev'}
            customClassNames={{
              rpbItemClassName:'custom-item',
              rpbItemClassNameActive:'custom-item--active',
              rpbGoItemClassName: 'custom-go-item',
              rpbItemClassNameDisable: 'custom-item--disable', 
              rpbProgressClassName: 'custom-progress-bar',
              rpbRootClassName: 'custom-root',
            }}
          />
        </div>
        {renderPages(activePage-1)}
      </>
    )
};

export default SpecialCardsAlbum;