import React from 'react';

const Cookies = () => {
    return (
        <>
        <p className='headline_essential'><strong>Cookie Policy</strong></p>

        <div className='essent-div-data'>
        <p>Current version: 1.0 - 15.05.2023</p>        

        <p className='cookie_headline'>1. Foreword</p>
        <p>The Company enables users (“User”) registered on the Website sorare-album.com (“Website”) to benefit from an enhanced user interface. 
        To enable Users to benefit from the Services offered by the Website https://sorare-album.com/ such as its consultation, optimization of its use or personalization according to the User, the Website uses cookies.</p>

        <p className='cookie_headline'>2. What is a cookie?</p>
        <p>2.1 As you browse through the Website, data pertaining to your terminal's navigation (such as a computer, tablet, smartphone, etc.) on the Website may be saved in text files known as "cookies," which are stored on your browser. Cookies are utilized to identify your browser during the cookie's lifespan and send back specific details to the Website, such as a session identifier or language preference.</p>
        <br></br>
        <p>2.2 The information stored in a cookie can only be accessed or altered by the sender who originally placed the cookie.</p>
        <br></br>
        <p className='cookie_headline_sm'>2.3 There are different types of cookies:</p>
        <ul>
        <li className='cookie_bulletin'>session cookies that disappear as soon as you leave the website;</li>  
        <li className='cookie_bulletin'>persistent cookies are stored on your device until they expire or until you remove them using the functions provided by your browser.</li>
        </ul>
        <br></br>
        <p>2.4 You are informed that, during your visits to the Website, cookies may be placed on your terminal equipment.</p>

        <p className='cookie_headline'>3. Purposes of cookies placed on terminal</p>
        <p className='cookie_headline_sm'>3.1 Certain cookies are necessary for the proper functioning of the Website. These are technical cookies employed by the Website host to manage the network's technical aspects.</p>
        <br></br>
        <p className='cookie_headline_sm'>3.2 Others are used to analyze the frequentation and the use made of the Website, in order:</p>
        <ul>
        <li className='cookie_bulletin'>to make it evolve in the sense of an improvement of the experience of navigation, in the final objective to propose you a Website always more adapted; to conduct studies;</li>
        <li className='cookie_bulletin'>to memorize your browser's display preferences (language used, display parameters, operating system used, etc.) and to adapt the presentation of the Website to the time of your visits, according to the hardware and software of visualization or reading that your terminal equipment includes and which are used for navigation on our Website;</li>
        <li className='cookie_bulletin'>to implement security measures.</li>
        </ul>
        <br></br>        
        <p className='cookie_headline_sm'>3.3 Cookies used on our Website are::</p>
        <ul>
        <li className='cookie_bulletin'>PHPSESSID: This cookie is used for the login system to remember the user is logged in. It is deleted when the browser is closed.</li>
        <li className='cookie_bulletin'>CookieConsent: This cookie is used to confirm that you read the cookie notice. This cookie has a lifetime of half a year.</li>
        </ul>
        <br></br>
        <p className='cookie_headline_sm'>3.4 Cookies from Sorare:</p>
        <p>To display the data and cards from Sorare, cookies from Sorare are placed in the browser. You can read more about Sorare's cookie policy <a href='https://sorare.com/cookie-policy' target="_blank" rel="noopener noreferrer">here</a>.</p>
        <br></br>
        
        {/*4. Personal Data processed
        4.1 Cookies used on our Website are:

        Sorare’s cookies ;
        third party cookies chosen by SORARE Company in order to achieve certain objectives
        4.2 Cookies placed by Sorare and third parties are the following:

        Name	Purpose	Retention period
        __stripe_mid	This cookie is set by Stripe to ensure that the service works properly, detect and prevent fraud, and understand how people interact with it. More information on the Stripe cookie policy.	1 year
        __stripe_sid	This cookie is set by Stripe to ensure that the service works properly, detect and prevent fraud, and understand how people interact with it. More information on the Stripe cookie policy.	30 minutes
        ajs_anonymous_id	This cookie is set by Segment.io to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Segment privacy policy.	6 months
        ajs_user_id	This cookie is set by Segment.io to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Segment privacy policy.	6 months
        scid	This cookie is set by Snap to anonymously identify visitors to measure the update of information and potential conversion to registered users through Snapchat. More information on Snap privacy policy.	6 months
        ab.storage	This cookie is set by Braze anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Braze privacy policy.	6 months
        ga	This cookie is set by Google Analytics to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Google privacy policy.	6 months
        gcl_au	This cookie is set by Google Tag Manager to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Google privacy policy.	3 months
        gid	This cookie is set by Google Analytics to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Google privacy policy.	1 day
        hjAbsoluteSessionInProgress	This cookie is set by Hotjar to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Hotjar privacy policy.	30 minutes
        hjFirstSeen	This cookie is set by Hotjar to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Hotjar privacy policy.	30 minutes
        hjIncludedInSessionSample	This cookie is set by Hotjar to anonymously identify visitors to measure the update of information and potential conversion to registered users. More information on Hotjar privacy policy.	30 minutes
        amplitude_id	This cookie is set by Amplitude to anonymously visitors to measure the update of information and potential conversion to registered users. More information on Amplitude privacy policy.	session
        xsrftoken	This cookie is set by Sorare as a security measure.	Session
        _sorare_session	This cookie is set by Sorare when you are logged in order to remember the current user.	Session
        4.3 The placement and use of cookies by these companies is subject to their own terms of use, which you can view on their respective websites.

        5. Sharing your terminal use with others
        5.1 If your terminal is used by several persons, and when a single terminal has multiple browsers, we cannot be certain that the services and advertisements directed to your terminal equipment correspond to your own use of that terminal equipment and not to that of another User of that terminal equipment.

        5.2 Sharing with others the use of your terminal equipment and configuring your browser settings with respect to cookies is your free choice and responsibility.

        6. Consent
        6.1 When you first visit our Website, you will be asked to accept, configure or refuse the use of some cookies.

        6.2 If you do not want cookies to be placed or read on your terminal equipment, a refusal cookie will be placed on your equipment so that SORARE can record the information according to which you have refused the use of cookies. If you delete this refusal cookie, it will no longer be possible to identify you as having refused the use of cookies.

        6.3 Also, when you accept the deposit of cookies, a consent cookie is placed.

        6.4 Refusal or consent cookies must remain on your terminal equipment. You can change your choices at any time by clicking here.

        7. Cookie management
        7.1 You can manage and modify the use of cookies at any time according to the possibilities listed below:

        directly on our Website, in the module provided for this purpose, by clicking here, or
        from your browser, or
        from interprofessional opposition platforms.
        7.2 Please note your refusal to place a cookie is based on the deposit of a cookie. Therefore, if you deactivate all cookies on your terminal or if you change your terminal, we will no longer know that you have chosen this option.

        7.3 Cookie management module

        7.4 A module allows you to choose which cookies you wish to accept and which you wish to reject on our Website.

        7.5 At any time, by clicking here you can access the module and change your preferences.

        7.6 Navigation software settings

        7.7 You can configure your browser so that cookies are stored in your terminal equipment or are rejected, either systematically or according to their sender.

        7.8 You can also regularly delete cookies from your terminal via your browser.

        7.9 However, don't forget to configure all the browsers on your various terminals (tablets, smartphones, computers...).

        7.10 Each browser is configured differently in order to manage cookies and your choices. It is described in the help menu of your browser, which will allow you to know how to modify your choices regarding cookies. For example:

        for Internet Explorer™: http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies;
        for Safari™ : https://support.apple.com/fr-fr/guide/safari/sfri11471/mac;
        for Chrome™: http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647;
        for Firefox™: http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies ;
        for Opera™: http://help.opera.com/Windows/10.20/fr/cookies.html.
        7.11 Please note, however, that by setting your browser to reject cookies, certain features, pages and areas of the Website that require the use of cookies will not be accessible, for which we cannot be responsible.

        7.12 To learn more about how to configure your browser, we invite you to consult the site of the Commission Nationale de l'Informatique et des Libertés: https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.

        7.13 Interprofessional opposition platforms

        7.14 Several advertising professionals' platforms also offer you the possibility to refuse or accept cookies used by the member companies. These centralized mechanisms do not block the display of advertisements but only prevent the installation of cookies that allow the adaptation of advertisements to your interests and behavior.

        7.15 For example, you can connect to the site www.youronlinechoices.com in order to prevent these cookies from being placed on your terminal. This Website is proposed by the professionals of digital advertising gathered within the European association EDAA (European Digital Advertising Alliance) and managed in France by the Interactive Advertising Bureau France.

        7.16 You will be able to find out which companies are registered with this platform and which offer you the possibility of refusing or accepting the cookies they use to adapt, according to the data they collect, the advertisements that may be displayed on your browser: www.youronlinechoices.com/fr/controler-ses-cookies.

        7.17 This European platform is shared by hundreds of Internet advertising professionals and constitutes a centralized interface allowing you to express your refusal or acceptance of cookies as specified above.

        8. Personal Data Protection
        8.1 Within the framework of the use of cookies as described on the present page, the SORARE Company is likely to process Personal Data concerning you, such as IP addresses, in its capacity as data controller.

        8.2 Third-party cookies on the Website are set by external processors, which are likely, if you accept these cookies, to process Personal Data about you.

        8.3 The issuance and use of these cookies by third parties is subject to their own privacy policies. For more information about these processes, you can refer to their privacy policies.

        8.4 The data collected is essential to achieve the objectives pursued by each cookie. It is only intended for the authorized departments of Sorare and/or the Company issuing the third-party cookies.

        8.5 Personal Data about you may be transmitted to service providers located in third countries outside the European Union.

        8.6 These data transfers are governed by a cross-border flow agreement established in accordance with the contractual clauses issued by the European Commission and currently in force.

        8.7 Personal Data collected via cookies is never kept longer than necessary to achieve the purpose of the cookie and, in any case, not more than 13 months.
        */}

        <p>For more information, including how to exercise your rights, see our <a href="dataprivacy">Privacy Policy</a>.</p>            
        </div>
        </>
    );
};

export default Cookies;