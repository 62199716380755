import React, { useContext, UseContext, useState, useEffect} from 'react';
import axios from "axios";
import '../Styles/User.css'
import '../Styles/Modal.css'
import Toggle from '../Material/Toggle';
import ModalDelete from '../Material/ModalDelete';
import AuthContext from '../Contexts/AuthProvider';
import { listItemButtonClasses } from '@mui/material';
import ChangeMail from './ChangeMail';
import ChangePassword from './ChangePassword';
import { Alert, AlertTitle } from '@mui/material/';
import { FcApproval, FcCancel } from "react-icons/fc";

function Account() {

  const { auth, setAuth } = useContext(AuthContext);
    
  const [modalOpen, setModalOpen] = useState(false);
  const [changePW, setChangePW] = useState(false);
  const [changeMail, setChangeMail] = useState(false);

  //const [user, setUser] = useState();
  const [disabledState, setDisabledState] = useState(true);
  const [rarity, setRarity] = useState(auth.rarity);
  const [verified, setVerified] = useState();
  const [success, setSuccess] = useState(false);
  const [successVerified, setSuccessVerified] = useState(false);
  let verifiedDB;
  const [mail, setMail] = useState();
  const [slug, setSlug] = useState();
  const [common, setCommon] = useState(auth.common);
  let commonDB;
  const [level, setLevel] = useState();
  const [role, setRole] = useState(); 

  axios.defaults.withCredentials = true;

  const getUserData = () => {
    axios.get("https://sorare-album.com/public/login").then(
        (response) => {             
        if (response.data.loggedIn == true) {
            if(response.data.verified == 'True'){
              verifiedDB = true               
            }else if(response.data.verified == "False"){
              verifiedDB = false     
            }   
            if(response.data.common == 1){
              commonDB = true               
            }else if(response.data.common == 0){
              commonDB = false     
            }   
            setAuth({
                user: response.data.user, 
                loggedIn: true,
                rarity: response.data.rarity, 
                common: commonDB,
                role: response.data.role,
                verified: verifiedDB,  
                level: response.data.level,
                mail: response.data.mail,
                slug: response.data.slug,
                sorareID: response.data.sorareID
            })        
        }
        else{
            setAuth({loggedIn: false})
        }
    });
  }

  axios.defaults.withCredentials = true;

  const sendVerificationMail = () => {
    axios.post("https://sorare-album.com/public/verify", {
      mail: auth.mail,
    }).then((response) => {
      if(response){
      setSuccessVerified(true)
      }
    })
  }
  
  const updateProfile = () => {
    axios.post("https://sorare-album.com/public/account", {
      rarity: rarity,
      level: level,
      common: common,
      mail: auth.mail,
    }).then((response) => {
      if(response){    
        getUserData();
        setDisabledState(true);
        setSuccess(true);
      }
    })
  };

  const deleteSorareConnection = () => {
    axios.post("https://sorare-album.com/public/oauth/delete", {
      mail: auth.mail,
    }).then((response) => {
      if(response){    
        getUserData()
      }
    })
  };

  return (     
    <div className='mainbody'>   
      <div className='register'>          
        <section>
          <p className='headline'>Profile</p>
          {success ?
          <><Alert onClose={() => setSuccess(false)} severity="success">
              <AlertTitle>Success!</AlertTitle>
              Your account settings have been changed.
          </Alert><br></br></>
          : 
          <></>
          }
          <div className='account_container'>
          <p className='leftdesc side'>E-Mail:</p> 
          <p className='rightdesc side'>{auth.mail}</p>       
          </div>

          <p className='leftdesc'>Rarity:
              <select className='rightfield leftdesc rounded inputs' value={rarity} onChange={e=>setRarity(e.target.value)} disabled={disabledState}>
                  <option>all</option>
                  <option>limited</option>
                  <option>rare</option>
                  <option>super_rare</option>
                  <option>unique</option>                    
              </select>
          </p>    

          <div className='account_container'>          
          <p className='leftdesc side'>Commons:</p>
          <p className='rightdesc side'> <input
            type="checkbox"
            checked={common}
            onChange={e=>setCommon(!common)}
            disabled={disabledState}
          /> {common ? <>Yes</> : <>No</>}</p>
          </div>

          {/*{level != undefined ? <p className='leftdesc'>Show card level?: <Toggle toggled={level}/></p> : <p className='leftdesc'>Show card level?: </p> *}
        
          {/* <p className='leftdesc'>Show special cards?:<Toggle/></p> */}
               
          <div className='account_container'>          
          <p className='leftdesc side'>Sorare:</p>
          {auth.sorareID ? 
          <><p className='rightdesc side'><button className='deletion' onClick={deleteSorareConnection}>Disconnect</button></p></> 
          : 
          <>
          <a href='https://sorare.com/oauth/authorize?client_id=OurTyA5y291P8egltu_PpYAPeuWoXPHtwhGeGvOUHrU&redirect_uri=https://sorare-album.com/auth/callback&response_type=code&scope='><button className='rightfield positive'>Connect</button></a>
          </>}
          </div>

          {successVerified ? 
          <><Alert onClose={() => setSuccessVerified(false)} severity="success">
          <AlertTitle>Success!</AlertTitle>
          A verification mail has been sent.
          </Alert><br></br></>
          : <></>}
          
          <div className='account_container'>          
          <p className='leftdesc side'>Verified:</p>{auth.verified ? <p className='rightdesc side'>Verified</p> : <button onClick={sendVerificationMail} className='rightfield positive'>Get verified</button>}
          </div>

          {/* <div className='account_container'><p className='leftdesc side'>Role:</p><p className='rightdesc side'>{auth.role}</p></div>
          <br></br> */}

          {changePW ? <></> : <button className='positive centered_button bold_button' onClick={() => setChangeMail(!changeMail)}>{changeMail ? <a>Cancel</a> : <a>Change Mail</a>}</button>}
          <br></br>
          {changeMail ? <></> : <button className='positive centered_button bold_button' onClick={() => setChangePW(!changePW)}>{changePW ? <a>Cancel</a> : <a>Change password</a>}</button>}
          
          {changeMail ? <ChangeMail/> : <></>}

          {changePW ? <ChangePassword/> : <></>}
          <br></br>    
          {!changeMail && !changePW ? <><br></br><br></br><button className='deletion centered_button' onClick={() => {setModalOpen(true)}}>Delete Account</button></> : <></>}      
          {disabledState ? <button className='edit' onClick={() => setDisabledState(false)}>Change</button> : <button className='edit' onClick={updateProfile}>Update</button>}                
        </section>        
        {modalOpen && <ModalDelete setOpenModal={setModalOpen} />}
      </div>
    </div>
  );
};

export default Account;