import React, {useState, useEffect} from 'react'
import '../Styles/Competitions.css'
import '../Styles/Club.css'
import {Link} from 'react-router-dom'
import logo from '../Styles/nologo_160.png'
import flag from '../Styles/flag_international.png'

function Competitions() {
    
    const [data, setData] = useState([])

    useEffect(() => {
        fetch("https://sorare-album.com/public/get/competitions").then(
          res => res.json()
        ).then(
          data =>{
            setData(data)
          }
        )
      }, [])
      
      data.forEach(testa =>{   
        if(testa.pictureUrl==""){
          testa.pictureUrl = "https://sorare-album.com/assets/nologo_160.png";
        }
        if(testa.flagUrl=="None"){
          testa.flagUrl = false
        }
      })

      const renderComps = () => {
        return data.map(comp =>
          <div className='frame'>       
            <div className='frame_col'><p className='frame_compname'><Link to={`${comp.slug}`} state={{ data: [comp.pictureUrl, comp.name, comp.flagUrl] }}><img className='complogo' src={comp.pictureUrl} alt='competition-logo'/></Link></p></div>
            <div className='frame_col'><p className='frame_compname'><Link to={`${comp.slug}`} state={{ data: [comp.pictureUrl, comp.name, comp.flagUrl] }}><p className='frame_compname'>{comp.name}</p></Link></p></div>
            <div className='frame_col'><p className='frame_compname'>{comp.flagUrl ?  <img className='compflag' src={comp.flagUrl} alt='flag' />: <img className='compflag' src={flag} alt='flag' />}</p></div>  
            {/* <img className='compflag' src={comp.flagUrl} width='25%' alt='flag-picture' /> */}
          </div>)
      }
    
      return(
        <div className='mainbody'>
          <p className='headline_comp bold'>Competitions</p>
          <div className='outer_frame'>
            {renderComps()}
          </div>
        </div>          
    );
}

export default Competitions;
