import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Club.css';
import { useParams, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { ColorRing } from 'react-loader-spinner'

function SpecialCards() {
  
    const { auth } = useContext(AuthContext);    
    const rarity = auth.rarity
    const userID = auth.sorareID 

    var params = useParams();
    let edition = params["editionslug"]
    let season = params["season"]
    let team = params["team"]

    var [editionname, setEditionName] = useState("")

    var [ownedCards] = useState(0);
    var [data, setData] = useState([])
    const [wallet, setWallet] = useState([])
    var slugwallet = []
    var loading = true; 

    //Link abhängig von season & team modifizieren und dann an fetch übergeben
    //Zusätzlich diese Variablen in der API nutzen

    useEffect(() => {
      const fetchData = async () => {
      await fetch(`https://sorare-album.com/public/get/edition/${edition}`).then(
        res => res.json()
      ).then(        
        data =>{
            setData(data)   
            setEditionName(data[0].name)
        }
      )}
      fetchData();      
      window.scrollTo(0, 0);
    }, [edition]);
 
    useEffect(() => {
      const fetchWallet = async () => {
        if(edition != undefined && rarity != undefined && userID != undefined){
        await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userspecialcards/${edition}&${rarity}&${userID}`).then(
          res => res.json()
        ).then(
          wallet =>{
            wallet.forEach(w =>{
              if(season == undefined){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              } else if(w.node.season.startYear == season){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              }
            })
          })
          setWallet(slugwallet)   
        }
      }
      fetchWallet();
    }, [edition, rarity, userID]);
    
    //Bei manchen nach Mannschaft filtern      
    if(team != undefined){
      data = data.filter(function(item) { return item.teamname == team; });
    }

    //Falls vorhanden nach Saison filtern
    if(season != undefined){
      data = data.filter(function(item) { return item.season == season; });
    }

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    //Zusätzlich Karten nach Appearances sortieren
    data = data.sort(function(left, right) {
      var city_order = sortByObject[left.position] - sortByObject[right.position];
      return city_order
    });

    var pics = [] 
    var link;

    data.forEach(p => { 
      //affiliate link to sorare
      if(season != undefined){
        link = "https://sorare.pxf.io/c/4295270/1590029/12209?u=https%3A%2F%2Fsorare.com%2Ffootball%2Fplayers%2F" + p.slug + "%2Fcards%3Fseason%3D" + season + "%26edition%3D" + p.buylink
        //"https://sorare.com/players/" + p.slug + "/cards?s=Lowest%20Price&season=" + season + "&edition=" + p.buylink
      }
      //Bei National_Series muss der Filter für Custom Series Karten aktiviert werden
      if(edition == "national_series"){
        link = link + "&co=true"
      }
      else if(season == undefined){
        link = "https://sorare.pxf.io/c/4295270/1590029/12209?u=https%3A%2F%2Fsorare.com%2Ffootball%2Fplayers%2F" + p.slug + "%2Fcards%3Fs%3DLowest%2BPrice%26edition%3D" + p.buylink
        //"https://sorare.com/players/" + p.slug + "/cards?s=Lowest%20Price" + "&edition=" + p.buylink
      }
      
      pics.push(
      <>                
      <div key={p.playername} className='card_box'>    
      <div className='inner_blanco'>
          <p className='nameheadline_club'>{p.playername}</p>
          <img className='imgplayerclub' src={p.pictureUrl} alt='Spielerfoto' />
          <p className='desc_club'>Position: {p.position}</p>
          <p className='desc_club'>Age: {p.age}</p>
          {/* vorläufige Lösung für zu lange Schrift */}
          {p.teamname.length > 24 ? <p className='desc_club' style={{'font-size': '0.9rem'}}>{p.teamname}</p> : <p className='desc_club '>{p.teamname}</p>}
          <p className='desc_club'>Season: {p.season}</p>
          <img className='imgflagclub' height={"20px"} src={p.flagUrl} alt='flag' />
     </div>
     <p className='desc_club'><a href={link} target="_blank" rel="noopener noreferrer"><button className='findbutton'>Find on Sorare</button></a></p>
     </div>  
     </>)
    })

    data.forEach((p, index) =>{
      for (let i = 0; i < wallet.length; i++){
        if(p.playername == wallet[i][0]){
          ownedCards = ownedCards+1;
          pics[index] = (<div className='card_box'><div className='inner_owned'>
          <img src={wallet[i][2]} className="img_owned" alt='card'/> 
        	</div></div>)   
          break;
        }        
      }
      if(index == data.length-1){
        loading = false;
      }
    })    

    const renderCards = () => {
      return pics.map(p => <div key={p.name}>{p}</div>)         
    }

    return(    
      <div className='mainbody'>
        {loading  ? 
          <>       
          <div className='loading_spinner'>
            <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#efc41f', '#870604', '#27219f', '#420f0a']}
            />
          </div>
          </>
          : 
          <>
          <div className='header_comps'>
            <div className="row">
                <div className='col_width'> 
                  <div>
                    <p className='clubname left_col'>{editionname}</p> 
                  </div> 
                </div>
                <div className='col_width'>
                  <div>         
                    <p className='clubname'>{team}</p>          
                    {season != undefined ? <p className='club_info'>Season: {season}</p> : <p></p>}            
                  </div>
                </div>
                <div className='col_width'>
                  <div>
                    <p className='clubname'>Owned cards: {ownedCards}/{data.length}</p>            
                    <Link to={`album`}  state={{ data: data, wallet: wallet }}> <button className='button_album'>See Album</button></Link>  
                  </div>
                </div>
            </div>
          </div>        

          <div className='outer_cards'>
            {renderCards()}
            </div>
          </>}
      </div>
    )
};

export default SpecialCards;