import React, { useContext, useState, useEffect} from 'react';
import"../Styles/Album.css";
import"../Styles/Competitions.css";
import { useParams, Link} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import ReactPaginate from 'react-paginate'
import axios from "axios";
import { Alert, AlertTitle } from '@mui/material/';

const Experiments = () => {

    const auth = useContext(AuthContext);    
    const mail = auth.auth.mail;
  
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    /* axios.post("https://api.sorare.com/oauth/token", {
        client_id: "OurTyA5y291P8egltu_PpYAPeuWoXPHtwhGeGvOUHrU",
        client_secret: "xS6qMnsFlmhNI0edtqqxuspCNCeCXwvha7GpJuqoDIQ",
        code: test,
        grant_type: "authorization_code",
        redirect_uri: "http://localhost:3000/auth/callback",
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }) */

    useEffect(() => {
        if(code != undefined){
            axios.post("https://sorare-album.com/public/authWithId", {
            code: code,
            mail: auth.auth.mail,
        }).then((response) => {
            if(response.status == 200){
                setSuccess(true)
            } else {
                setError(true)
            }
        })
        }       
    }, [code, mail]);    

    const getToken = () => {
        axios.post("https://sorare-album.com/public/authWithId", {
            code: code,
            mail: mail,
        }).then((response) => {
            //auth_token/id bei auth einsetzen
        })
    }
     
   
    return(  
        <div className='mainbody'> 
        <a href='https://sorare.com/oauth/authorize?client_id=OurTyA5y291P8egltu_PpYAPeuWoXPHtwhGeGvOUHrU&redirect_uri=https://sorare-album.com/auth/callback&response_type=code&scope='><button className='findbutton'>Connect with Sorare</button></a>
        {/* <button onClick={getToken}>Post Access Token</button> */}
        {success ? 
            <>
            <br></br>
            <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
                Connected with Sorare. 
            </Alert>
            </> 
            : <></>
        }
        {error ? 
            <>
            <br></br>
            <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We are currently having a problem. Try later or send a mail to our support. 
            </Alert>
            </> 
            : <></>
        }
        </div>
    );
};

export default Experiments;

    
/*
    const renderSecondPage = () => {
        if(teams != []){
        return (<div className='outer_cover'>
            <TableContainer component={Paper}>
            <Table sx={{ width: "100%"}} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>Logo</StyledTableCell>
                    <StyledTableCell>Club</StyledTableCell>
                    <StyledTableCell>Rank</StyledTableCell>
                    <StyledTableCell>Points</StyledTableCell>
                    <StyledTableCell>Won</StyledTableCell>
                    <StyledTableCell>Draw</StyledTableCell>
                    <StyledTableCell>Lost</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {teams.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell><img className='tablelogofirstpage' src={row.pictureUrl}/></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.clubname}</p></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.rank}</p></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.points}</p></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.matchesWon}</p></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.matchesDrawn}</p></StyledTableCell>
                    <StyledTableCell><p className='tablefontfirstpage'>{row.matchesLost}</p></StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </div>)
    }
} */