import React from 'react';

const Support = () => {
    return (
        <div className='mainbody'>
            <div className='essent-div-data'>
                <p className='headline_essential'><strong>Support Sorare Album</strong></p>
                <br></br>

                <p className='support_text'>If you enjoy using Sorare Album to showcase your Sorare card collection, there are a couple of ways that you can support our platform and help us to continue improving and developing it.</p>
                <br></br>

                {/* <p className='support_text'>One way to support Sorare Album is by using our affiliate link when you purchase new cards on Sorare. When you want to buy a new card click on the "Find on Sorare" button under a card in the <a href="/competitions/premier-league-gb-eng/2022/liverpool-liverpool">club view</a>.<br></br>By doing so, you are helping to generate a small commission. It's a simple and easy way to support us, and it doesn't cost you anything extra.</p>
                <br></br> */}

                <p className='support_text'>One way to support Sorare Album is by using our <a href='https://sorare.pxf.io/c/4295270/1590032/12209?subId1=supportpage'>affiliate link</a> when you create an account on Sorare. 
                When you want to start with Sorare or want to recommend it to a friend you can use this link sorare.pxf.io/support23 to support us. When you register with this link you get a free limited card after you've bought 5 cards from auctions.
                <br></br>
                <br></br>
                By creating an account with our link, you are helping to generate a small commission for the cards you buy on the auction market. It's a simple and easy way to support us, and it doesn't cost you anything extra.</p>
                <br></br>

                <p className='support_text'>Another way to support Sorare Album is by making a donation. You can donate in the form of:</p>
                <br></br>
                <ul>
                    <li>Buy me a coffee:</li>
                    <br></br>
                    <a href="https://www.buymeacoffee.com/sorarealbum" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" className='bmacpic' alt="Buy Me A Coffee"/></a>                   
                    <br></br>
                    <li>Crypto: ETH-address: 0x84BB6e8964708373bc4290BC77E48145041b290B</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/ETH_address.jpg"} className="walletpic" alt='QR code for ETH address'/>
                    <br></br>
                    <li>Crypto: BTC-address: bc1q2xd57qqx7jsjq34k6evta5fmqnjzed52qmeh4u</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/BTC_address.jpg"} className="walletpic" alt='QR code for BTC address'/>
                    <br></br>
                    <li>Paypal:</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/pp_qr.jpg"} className="walletpic" alt='QR code for BTC address'/>
                    <br></br>
                    <li>Sending me cards: <a href='https://sorare.com/football/gallery/nowag-a37c3f14-7f38-4c56-aa4f-ff280d4d2ca2/overview' target="_blank" rel="noopener noreferrer"><button className='supportbutton'>My account on Sorare</button></a></li>
                </ul>
                <p className='support_text'>We are incredibly grateful for any support that you can offer, and we want to make sure that Sorare Album continues to be a valuable resource for Sorare collectors worldwide. <br></br>
                Thank you for your support, and we look forward to continuing to enhance your Sorare collecting experience.</p>
                <br></br>
                <p className='support_text'>To achieve this vision I welcome any feedback or suggestions that can help improving Sorare Album. Send a mail to: support@sorare-album.com</p>
                <a href='mailto:support@sorare-album.com'><button className='supportbutton support_text'>Send E-Mail</button></a>
                <br></br>
            </div>
        </div>
    );
};

export default Support;