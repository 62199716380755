import React, {useState, useEffect, useContext} from 'react'
import '../Styles/Album.css';
import '../Styles/Club.css';
import { useParams, useLocation} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import { jsPDF } from 'jspdf';
import { toPng, toJpeg } from 'html-to-image';
import * as htmlToImage from 'html-to-image';
import { Alert, AlertTitle } from '@mui/material/';

import { CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function SpecialCardsAlbumPDF(props) {

    const location = useLocation();
  
    const { auth } = useContext(AuthContext);    
    const rarity = auth.rarity
    const userID = auth.sorareID 

    var params = useParams();
    let edition = params["editionslug"]
    let season = params["season"]
    let team = params["team"]

    const [album, setAlbum] = useState(false);
    var [currentPage, setCurrentPage] = useState(0);
    var [activePage, setActivePage] = useState(1)

    var [user, setUser] = useState()
    var [username, setUsername] = useState("")
    var [userpicture, setUserPicture] = useState("")
    var [userbanner, setUserBanner] = useState("")
    var [usershield, setUserShield] = useState("")

    var [editionname, setEditionName] = useState("")

    var [data, setData] = useState([])
    const [wallet, setWallet] = useState([])
    var [creatingPDF, setCreatingPDF] = useState(false)    
    var slugwallet = []  
    var loading=true;

    var cardscounter = 0;   

    useEffect(() => {
      if(userID != undefined){
          fetch(`https://sorare-album.com/cgi-bin/backend.cgi/user/${userID}`).then(
              res => res.json()).then(user =>{
                  setUser(user)  
                  setUsername(user.nickname)                 
                  setUserPicture(user.pictureUrl)    
                  setUserBanner(user.profile.clubBanner.pictureUrl)             
                  setUserShield(user.profile.clubShield.pictureUrl)             
          })
      }
    }, [userID]);

    useEffect(() => {
      if(location.state == undefined){
        fetch(`https://sorare-album.com/public/get/edition/${edition}`).then(
          res => res.json()
        ).then(        
          data =>{
            setData(data)    
            setEditionName(data[0].name)
          }
        )
      } else {
        setData(location.state.data)
        setEditionName(location.state.data[0].name)
      }
      window.scrollTo(0, 0);
    }, []);
 
    useEffect(() => {
        if(rarity != undefined&& userID != undefined && location.state == undefined){
          fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userspecialcards/${edition}&${rarity}&${userID}`).then(
          res => res.json()
        ).then(
          wallet =>{
            wallet.forEach(w =>{
              if(season == undefined){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              } else if(w.node.season.startYear == season){
                slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp])
              }
            })
        })
        setWallet(slugwallet)         
        } else if (location.state != undefined){
          setWallet(location.state.wallet)
        }
      }, [edition, rarity]);

    
    //Bei manchen nach Mannschaft filtern      
    if(team != undefined){
      data = data.filter(function(item) { return item.teamname == team; });
    }

    //Falls vorhanden nach Saison filtern
    if(season != undefined){
      data = data.filter(function(item) { return item.season == season; });
    }

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

    //Zusätzlich Karten nach Appearances sortieren
    data = data.sort(function(left, right) {
      var city_order = sortByObject[left.position] - sortByObject[right.position];
      return city_order
    });     

    var pics = [] 

    var link;
    data.forEach(p => {      
      
      pics.push(
      <>                    
      <div className='inner_card_pdf'>
          <p className='nameheadline_pdf'>{p.playername}</p>
          <img className='imgplayer_pdf' src={p.pictureUrl} alt='Spielerfoto' />
          <p className='desc_pdf'>Position: {p.position}</p>
          <p className='desc_pdf'>Age: {p.age}</p>    
          {/* vorläufige Lösung für zu lange Schrift */}
          {p.teamname.length > 24 ? <p className='desc_pdf' style={{'font-size': '0.9rem'}}>{p.teamname}</p> : <p className='desc_pdf'>{p.teamname}</p>}
          <p className='desc_pdf'>Season: {p.season}</p>
         <img className='imgflag_pdf' src={p.flagUrl} height="20px" alt='flag' />
     </div>
      </>
      )
    })

    data.forEach((p, index) =>{
      for (let i = 0; i < wallet.length; i++){
        if(p.playername==wallet[i][0]){
          cardscounter = cardscounter+1;
          pics[index] = (<div className='inner_limited_pdf'>
          <img src={wallet[i][2]} className="imgcard_pdf" alt='card'/> 
        	</div>)   
        }        
      }
    })    

    const c = Math.ceil(pics.length/9)
    const pages = []
    for (let index = 0; index < c; index++) {
      pages.push(pics.slice(9*index,(index+1)*9)) 

      if(index == pages.length-1){
        loading = false;          
      }  

    } 

    /* const renderFirstPage = () => {
      return( 
          <div>
              <p className='fontcover'>{editionname}</p>
              {season ? <p className='fontcover'> Season: {season}</p> : <p></p>}

              <div className='coverBackground_pdf'>                    
                  {/* <div className='featured_card_box'>
                      <div className='featured_card_pdf'>{golden_boot_pic}</div>
                      <div className='featured_text'><p className='featured_text'>golden boot: {golden_boot_stat}</p></div>
                  </div>
                  <div className='featured_card_box'>
                      <div className='featured_card_pdf'>{top_assist_pic}</div>
                      <div className='featured_text'><p className='featured_text'>most assists: {top_assist_stat}</p></div>
                  </div> */
             /*  </div>

              <div className='manager_data'>                    
                  <div className='featured_text'><p className='featured_text'>Manager: {username}</p></div>
                  <div className='featured_text'><p className='featured_text'>Cards: {cardscounter}</p></div>
                  {userpicture != "" && userpicture != undefined ? <img className='imgflag' src={userpicture} alt='Userpicture'/> : <p></p>}
                  {/* {userbanner!=undefined ? <img className='imgflag' src={userbanner} alt='Userpicture'/> : <p></p>}
                  {usershield!=undefined ? <img className='imgflag' src={usershield} alt='Userpicture'/> : <p></p>}*/
         /*      </div>
          </div>
      )
  } */  
  /* 
  pages.unshift(renderFirstPage()) */

    const printRef = React.useRef();
    
    const renderPages = (numb) => {
      return pages.map((p, idx) => <div className='outer_box_pdf'>{p}{idx != 0 ? <p className='pagenumber_pdf'>{idx}</p> : <p></p>}</div>)
    }

    async function exportMultipleChartsToPdf() {
      const doc = new jsPDF(); 

      const elements = document.getElementsByClassName("outer_box_pdf"); // (2)     
    
      setCreatingPDF(true)

      await creatPdf({ doc, elements }); 

      setCreatingPDF(false)
      //season hinzufügen wenn nicht undefined
      const name = `${edition}.pdf`;
      doc.save(name); 
    }

    var [pagecounter, setPageCounter] = useState(0);

    async function creatPdf({doc, elements}) {          
        var i = 0;
        while (i < elements.length) {
            const el = elements.item(i);
            const imgData = await htmlToImage.toJpeg(el, {cacheBust: true}); 

            //Umgehen, dass erste Seite leer ist
            if(i != 0){
            doc.addPage();
            }

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 0, width, height); 
            
            i = i+1;      
            setPageCounter(i);      
        }
    }
    
    return(  
      <div className='mainbody'>  
      <p className='headline_comp bold'>{editionname}</p> 
      {loading ? 
        <p className='headline'>Loading the album...</p> 
        : 
        <>
         <div className='info_alert'>
            <Alert variant="outlined" severity="info">
                <AlertTitle>Info</AlertTitle>
                Currently we're experiencing problems with the PDF downloads on apple devices.
                <br></br>
                We're sorry for the inconvenience. For now you can download PDF's on other devices correctly. We're working on a solution for the problem.
            </Alert>
        </div>
        <p className='headline'>
          <button className='main_button' type="button" text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
              Download as PDF
          </button>
        </p>            
        </>
      }
      <div>
        {creatingPDF ? <p className='headline'>Your PDF is being generated.</p> : <p></p>}
        <div className='loading_animation'><CircularProgressbar value={(pagecounter/pages.length)*100} text={`${(Math.round((pagecounter/pages.length)*100))} %`}/></div>
      </div>
        <div className='offscreen' style={{top: `-${pages.length * 29.7}cm`}}>{renderPages()}</div>
      </div>
    )
};

export default SpecialCardsAlbumPDF;