import React, {useState, useEffect} from 'react'
import '../Styles/Competitions.css'
import {Link} from 'react-router-dom'

function NBA() {

    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("https://sorare-album.com/public/get/nba").then(
          res => res.json()
        ).then(
          data =>{
            setData(data)
            window.scrollTo(0, 0);
          }
        )
      }, []);      

    const renderTeams = () => {
        return data.map(team =>
          <div className='frame'>       
            <div className='frame_col'><p className='frame_compname'><Link to={`${team.slug}`} state={{ team: team}}><img className='complogo' src={team.pictureUrl} alt='team-logo'/></Link></p></div>
            <div className='frame_col'><p className='frame_compname'><Link to={`${team.slug}`} state={{ team: team}}><p className='frame_compname'>{team.name}</p></Link></p></div>
            <div className='frame_col'><p className='frame_compname'>{team.division}</p></div>
          </div>)
    }
        
    return(
      	<div className='mainbody'>
          <p className='headline_comp bold'>NBA Teams</p>
          <div className='outer_frame'>
            {renderTeams()}
          </div>
        </div>       
    ); 
}

export default NBA;
