import React from 'react';
import "../Styles/Footer.css";
import "../Styles/Main.css";

const Footer = () => {

    return (
        <>
        <div className='footer'>
            <div className='footer_box'>
                <div className='footer_box_individual'>
                    <h3 className='footer_title'>Sorare Album</h3>                    
                    <p className='footer_text'><a className='foot-link' href="/about">About</a></p>
                    <p className='footer_text'><a className='foot-link' href="/support">Support</a></p>
                </div>

                <div className='footer_box_individual'>
                <h3 className='footer_title'>Help</h3>
                    <p className='footer_text'><a className='foot-link' href="/contact">Contact</a></p>
                    <p className='footer_text'><a className='foot-link' href="/help">FAQ</a></p>
                </div>

                <div className='footer_box_individual'>
                    <h3 className='footer_title'>Share</h3>
                    <a href="mailto:?subject=Checkout%20Sorare%20Album!&amp;body=Look%20into%20this%20site%20https://www.sorare-album.com. It's a tool to make collecting Sorare cards more fun. "><p className='footer_text'>Mail</p></a> 
                  
                    {/* <p className='footer_text'>Instagram</p>
                    <p className='footer_text'>Discord</p>
                    <p className='footer_text'>Twitter</p> */}
                </div>
            </div>
        </div>
        <hr></hr>
        <div className='footer_mid'>
            <p className='footer_mid_title'>
                Disclaimer
            </p>
            <p className='footer_mid_text'>
                Sorare Album is an independent platform that is not associated with Sorare. 
                We offer a service for creating digital albums for Sorare's trading cards.
                The information and content provided on Sorare Album is for informational purposes only and should not be considered as 
                investment or financial advice. We do not take responsibility for any investments or purchases made by our users.
                <br/>
                <br/>
                The graphics used on Sorare Album are reproduced. {/* by permission of Sorare, and we are grateful for the opportunity to feature 
                them on our platform. However, */} Sorare Album does not claim any ownership of these graphics and is not responsible for any 
                inaccuracies or discrepancies in the images.
                <br/>
                <br/>
                We reserve the right to modify, suspend, or discontinue any aspect of the platform at any time, including the availability of any feature,
                database, or content. We may also impose limits on certain features or restrict access to parts or all of the platform without notice 
                or liability.
            </p>
        </div>
        <hr></hr>
        <div className='footer_below'>
            <p className='copyright'>
                &copy;{new Date().getFullYear()} Sorare Album | All rights reserved
            </p>
            <p className='privacy'>
                <a className='foot-link' href="/dataprivacy">Data Privacy</a> |  <a className='foot-link' href="/impress">Impress</a> | <a className='foot-link' href="/cookie-policy">Cookie policy</a>
            </p>
        </div>
        </>
    );
};

export default Footer;