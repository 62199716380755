import React, { useCallback, useContext} from 'react';
import"../Styles/Album.css";
import"../Styles/AlbumPDF.css";
import"../Styles/Competitions.css";
import {useState, useEffect} from 'react'
import { useParams, Link, useLocation} from 'react-router-dom';
import AuthContext from '../Contexts/AuthProvider';
import ReactPaginate from 'react-paginate'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { toPng, toJpeg } from 'html-to-image';
import * as htmlToImage from 'html-to-image';
import { Alert, AlertTitle } from '@mui/material/';

import { CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AlbumCompPDF = (props) => {

    const location = useLocation();

    const auth = useContext(AuthContext);  
    
    const rarity = auth.auth.rarity
    const userID = auth.auth.sorareID
    const common = String(auth.auth.common);
  
    var params = useParams();
    const season = params["season"]
    const slug = params["compslug"]

    var [teams, setTeams] = useState([])    
    var [competition, setCompName] = useState("")
    var [compUrl, setCompUrl] = useState("")
    var [user, setUser] = useState()
    var [username, setUsername] = useState("")
    var [userpicture, setUserPicture] = useState("")
    var [userbanner, setUserBanner] = useState("")
    var [usershield, setUserShield] = useState("")
    var [data, setData] = useState([])
    var [datats, setDatats] = useState([])
    var [wallet, setWallet] = useState([])    
    var [creatingPDF, setCreatingPDF] = useState(false)    
    var loading=true;    
    var [loadingWallet, setLoadingWallet] = useState(true)

    var [pagecounter, setPageCounter] = useState(0);   
                    
    const pages = []
    var pics = []
    var cardscounter = 0;

    let currentteam = []
   
    useEffect(() => {
    fetch(`https://sorare-album.com/public/get/clubs/${slug}/${season}`).then(
        res => res.json()).then(teams =>{
            setTeams(teams)
            setCompName(teams[0].compname)
            setCompUrl(teams[0].compUrl)
    })
    }, [slug, season]);

    useEffect(() => {
        if(userID != undefined){
            fetch(`https://sorare-album.com/cgi-bin/backend.cgi/user/${userID}`).then(
                res => res.json()).then(user =>{
                    setUser(user)  
                    setUsername(user.nickname)                 
                    setUserPicture(user.profile.pictureUrl)    
                    setUserBanner(user.profile.clubBanner.pictureUrl)             
                    setUserShield(user.profile.clubShield.pictureUrl)             
            })
        }
    }, [userID]);

    if(teams != []){
        teams.forEach(t => {
            if(t.cardsprinted == "True"){
                currentteam.push(t.clubslug)
            }
        })
    }
    
    var players = []  
    var team = []  
    var slugwallet = []    

    useEffect(() => {
        async function fetchData(te) {    
            await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/cards/${season}&${te}`).then(
                res => res.json()
            ).then(data =>{
                setData(data)     
                if(data.result != "none"){
                players.push(data)}
            })            
            setDatats(players)
        };

        async function fetchWallet(te) {    
            //PHP API: https://sorare-album.com/public/userclubcards/${te}/${rarity}/${userID}/${common}
            await fetch(`https://sorare-album.com/cgi-bin/backend.cgi/userclubcards/${te}&${rarity}&${userID}&${common}`).then(
                res => res.json()
            ).then(wallet =>{
                setWallet(wallet)     
                wallet.forEach(w =>{
                    if(w.node.season.startYear == season){
                        slugwallet.push([w.node.player.displayName, w.node.season.startYear, w.node.pictureUrl, w.node.powerBreakdown.xp, w.node.team.slug])
                    }
                }
            )
            setWallet(slugwallet)
            })            
        };

        window.scrollTo(0, 0);

        if(currentteam.length != 0){
            currentteam.forEach((ct, idx) => {
                if(location.state == undefined){
                    fetchData(ct)
                }
                if(location.state == undefined && rarity != undefined && common != undefined){
                    fetchWallet(ct) 
                    if(idx == currentteam.length-1){
                        setLoadingWallet(false)
                    }
                }           
            })
            if(location.state != undefined){
                setDatats(location.state.data)
            }
            if(location.state != undefined){
                setWallet(location.state.wallet)
                setLoadingWallet(false)
            }
        }
    }, [JSON.stringify(currentteam)]);    
     

    //Karten nach Positionen sortieren
    const importOrder = ["Goalkeeper", "Defender", "Midfielder", "Forward"];

    const sortByObject = importOrder
    .reduce((obj, item, index) => {
        return {
        ...obj,
        [item]: index,
        };
    }, {});

    const sortByRank = currentteam
    .reduce((obj, item, index) => {
        return {
        ...obj,
        [item]: index,
        };
    }, {});

    //Teams nach Tabelle sortieren
    datats = datats.sort(function(left, right){
        return sortByRank[left[0].team.slug] - sortByRank[right[0].team.slug];
    }) 


    //Zusätzlich Karten nach Appearances sortieren
    datats.forEach(pl => {
        pl = pl.sort(function(left, right) {
        var position_order = sortByObject[left.player.positionTyped] - sortByObject[right.player.positionTyped];
        var app_order = parseInt(left.player.stats.appearances) - parseInt(right.player.stats.appearances);
        return position_order || -app_order;
    })});

    const renderTeams = () => {
        return teams.map(t => <><img className='coverClubs_pdf' src={t.pictureUrl}/>
        {/* <p className='featured_points'>Points: {t.points}</p> */}</>
        )
    }

    if(datats.length == currentteam.length){
        datats.forEach((pl, idx) => {
            
            pics.push(<><div className='inner_card_pdf'>           
            <p className='nameheadline_pdf'>{pl[0].team.name}</p>
            <img className='imgfblogo_pdf' src={pl[0].team.pictureUrl} alt='clublogo'/>
            <p className='smheadline_pdf'>Founded: {pl[0].team.founded}</p>
            <p className='smheadline_pdf'>Players: {pl.length}</p>
            </div></>
            )  

            pl.forEach(p => {
                players.push([p.player.displayName, p.team.slug])
                team.push(p.team.name)

                pics.push(<>                    
                    <div className='inner_card_pdf'>
                        <p className='nameheadline_pdf'>{p.player.displayName}</p>
                        <img className='imgplayer_pdf' src={p.pictureUrl} height={"100px"} alt='player' />
                        <p className='desc_pdf'>Appearances: {p.player.stats.appearances}</p>
                        <p className='desc_pdf'>Goals: {p.player.stats.goals} | Assists: {p.player.stats.assists}</p>
                        <p className='desc_pdf'>Position: {p.player.positionTyped}</p>
                        <p className='desc_pdf'>Age {p.age}</p>
                        <img className='imgflag_pdf' src={p.player.country.flagUrl} height="20px" alt='flag' />
                    </div>
                    </>)
            })

            //index+1, da zu Beginn der pics noch das Logo eingefügt wurde. 
            players.forEach((p, index) =>{
                for (let i = 0; i < wallet.length; i++){
                if(p[0]==wallet[i][0] && p[1]==wallet[i][4]){
                    cardscounter = cardscounter + 1;
                    pics[index+1] = (<div className='inner_limited_pdf'>
                    <img src={wallet[i][2]} className="imgcard_pdf" alt='owned card'/> 
                    </div>)   
                    break;
                }        
            }})

            const c = Math.ceil(pics.length/9)

            for (let index = 0; index < c; index++) {
                pages.push(pics.slice(9*index,(index+1)*9))   
                
                if(index == pages.length-1){
                    loading = false;          
                }  
            }      

            pics = []
            players = []
        })
    }; 

    const renderFirstPage = () => {
        if(teams != []){
            return( 
                <div>
                    <p className='fontcover_pdf'>{competition}</p>
                    <p className='fontcover_pdf'>Season: {season}</p>
                    <img className='compLogo_pdf' src={compUrl} alt='competition logo'/>
                   
                    <div className='clubsBackground_pdf'>
                        {teams.length < 25 ? renderTeams() : <p></p>}
                    </div>

                    <div className='manager_data'>                    
                        <div><p className='featured_text_manager'>Manager: {username}</p></div>
                        {userpicture != "" && userpicture != undefined ? <img className='userlogo' src={userpicture} alt='Userpicture'/> : <p></p>}
                        <div><p className='featured_text_manager'>Cards: {cardscounter}</p></div>                        
                        {/* {userbanner!=undefined ? <img className='imgflag' src={userbanner} alt='Userpicture'/> : <p></p>}
                        {usershield!=undefined ? <img className='imgflag' src={usershield} alt='Userpicture'/> : <p></p>} */}
                    </div>
                </div>
            )
        }
    }

    pages.unshift(renderFirstPage())

    const printRef = React.useRef();

    const renderPages = () => {
        return pages.map((p, idx) => <div className='outer_box_pdf'>{p}{idx != 0 ? <p className='pagenumber_pdf'>{idx}</p> : <p></p>}</div>)
    }

    async function exportMultipleChartsToPdf() {
        const doc = new jsPDF(); // (1)

        const elements = document.getElementsByClassName("outer_box_pdf"); // (2)     
        /* for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'visible'            
        } */
        setCreatingPDF(true)

        await creatPdf({ doc, elements }); // (3-5)

       /*  for (let index = 0; index < elements.length; index++) {
            elements[index].style.visibility = 'hidden'            
        } */
        setCreatingPDF(false)
        const pdf_title = `album_${competition}_${season}`
        doc.save(pdf_title); 
    }


    async function creatPdf({doc, elements}) {          
        var i = 0;
        while (i < elements.length) {
            const el = elements.item(i);
            //Bild erscheinen lassen
            //el.style.visibility = 'visible'   
            const imgData = await htmlToImage.toJpeg(el, {cacheBust: true}); 

            //Umgehen, dass erste Seite leer ist
            if(i != 0){
            doc.addPage();
            }

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, "PNG", 0, 0, width, height);  
            //Bild wieder verstecken
            //el.style.visibility = 'hidden'  
            i = i+1;      
            setPageCounter(i);      
        }
    }

    return(  
        <>
        {/* <p className='headline'>
        <button type="button"  text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
            Download as PDF
        </button>
        </p> */}
        <div className='mainbody'>
            <p className='headline_comp bold'>{competition} - {season}</p>
            {loading || loadingWallet ? 
                <p className='headline'>Loading the album...</p> 
                : 
                <>
                <div className='info_alert'>
                    <Alert variant="outlined" severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Currently we're experiencing problems with the PDF download on apple devices.
                        <br></br>
                        We're sorry for the inconvenience. For now you can download PDF's on other devices correctly. We're working on a solution for the problem.
                    </Alert>
                </div>
                <p className='headline'>
                    <button className='main_button' type="button" text-align="center" justify-content="center" onClick={exportMultipleChartsToPdf}>
                        Download as PDF
                    </button>
                </p>
                </>
            }
        <div>
            {creatingPDF ? <p className='headline'>Your PDF is being generated. {pagecounter}/{pages.length}</p> : <p></p>}
        </div>
        <div className='loading_animation'><CircularProgressbar value={(pagecounter/pages.length)*100} text={`${(Math.round((pagecounter/pages.length)*100))} %`}/></div>
        <div className='offscreen'>{renderPages()}</div>
        </div>
        </>
    );
};

export default AlbumCompPDF;